import React from 'react';
import { useSnackbar } from 'notistack';
import { Translate } from 'react-redux-i18n';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  snackWrapper: {
    '& span': {
      color: '#fff',
      fontWeight: 'bold',
    },
  },
});

const useError = () => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = key => (
    <div className={classes.snackWrapper}>
      <Button
        variant="text"
        onClick={() => {
          closeSnackbar(key);
          window.location.reload();
        }}
      >
        <Translate value="global.reload" />
      </Button>
    </div>
  );

  const handleError = (error, persist = true) => {
    const opts = { variant: 'error' };

    if (persist) {
      opts.persist = true;
      opts.action = action;
    }

    if (error.response) {
      enqueueSnackbar(error.response.data.error, {
        variant: 'error',
        autoHideDuration: 5000,
        ...opts,
      });
    } else {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 5000,
        ...opts,
      });
    }
  };

  return { handleError };
};

export default useError;
