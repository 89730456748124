import timePeriodServices from '../../services/timePeriod';

export const CREATE_TIME_PERIOD = 'CREATE_TIME_PERIOD';
export const UPDATE_TIME_PERIOD = 'UPDATE_TIME_PERIOD';
export const DELETE_TIME_PERIOD = 'DELETE_TIME_PERIOD';
export const FETCH_TIME_PERIODS = 'FETCH_TIME_PERIODS';

export function createTimePeriod(timePeriod) {
  return {
    type: CREATE_TIME_PERIOD,
    payload: timePeriodServices.create(timePeriod),
  };
}

export function updateTimePeriod(timePeriod) {
  // // Remove id if needed
  const timePeriodId = timePeriod._id;
  // delete timePeriod._id;

  return {
    type: UPDATE_TIME_PERIOD,
    payload: timePeriodServices.update(timePeriodId, timePeriod),
  };
}

export function deleteTimePeriod(timePeriod) {
  return {
    type: DELETE_TIME_PERIOD,
    payload: timePeriodServices.delete(timePeriod._id),
  };
}

export function fetchTimePeriods() {
  return {
    type: FETCH_TIME_PERIODS,
    payload: timePeriodServices.fetchTimePeriods(),
  };
}
