import React from 'react';

import { Translate } from 'react-redux-i18n';
import Button from '@material-ui/core/Button';

import useStyles from './styles';

const ForgottenPasswordSent = ({
  email,
  setForgottenPassword,
  setForgottenPasswordSent,
}) => {
  const classes = useStyles();

  const handleForgottenPasswordUpdate = event => {
    event.preventDefault();
    setForgottenPassword(false);
    setForgottenPasswordSent(false);
  };

  return (
    <form className={classes.forgotPasswordSent}>
      <h1 className={classes.header1}>
        <Translate value="login.emailSent.title" />
      </h1>
      <p>
        <Translate value="login.emailSent.description1" /> {email}
      </p>
      <p>
        <Translate value="login.emailSent.description2" />
      </p>

      <Button
        type="submit"
        className={classes.sendToLoginButton}
        onClick={event => handleForgottenPasswordUpdate(event)}
      >
        <Translate value="login.login" />
      </Button>
    </form>
  );
};

export default ForgottenPasswordSent;
