import alarmServices from '../../services/alarm';

export const CREATE_ALARM = 'CREATE_ALARM';
export const UPDATE_ALARM = 'UPDATE_ALARM';
export const DELETE_ALARM = 'DELETE_ALARM';
export const FETCH_ALARMS = 'FETCH_ALARMS';

export function createAlarm(alarm) {
  return {
    type: CREATE_ALARM,
    payload: alarmServices.create(alarm),
  };
}

export function updateAlarm(alarm) {
  // Remove id if needed
  const alarmId = alarm._id;
  delete alarm._id;

  return {
    type: UPDATE_ALARM,
    payload: alarmServices.update(alarmId, alarm),
  };
}

export function deleteAlarm(alarm) {
  return {
    type: DELETE_ALARM,
    payload: alarmServices.delete(alarm._id),
  };
}

export function fetchAlarms() {
  return {
    type: FETCH_ALARMS,
    payload: alarmServices.fetchAlarms(),
  };
}
