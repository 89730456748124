import React from 'react';

import { Translate } from 'react-redux-i18n';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import useStyles from './styles';

const ConfirmLogout = ({ confirmOpen, setConfirmOpen, signOut }) => {
  const classes = useStyles();

  const handleConfirm = () => {
    signOut();
    setConfirmOpen(false);
  };

  const handleCancel = () => {
    setConfirmOpen(false);
  };

  return (
    <Dialog
      open={confirmOpen}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Translate value="logout.title" />
      </DialogTitle>

      <DialogContent classes={{ root: classes.content }}>
        <Translate value="logout.text" />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" size="small" onClick={() => handleCancel()}>
          <Translate value="global.cancel" />
        </Button>

        <Button color="primary" size="small" onClick={() => handleConfirm()}>
          <Translate value="global.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmLogout;
