import analysisServices from '../../services/analysis';

export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_LAST_DATA = 'FETCH_LAST_DATA';

export function fetch(options) {
  return {
    type: FETCH_DATA,
    payload: analysisServices.fetch(options),
  };
}

export function fetchLast(options) {
  return {
    type: FETCH_LAST_DATA,
    payload: analysisServices.fetchLast(options),
  };
}
