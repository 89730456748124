import axios from './axios';

const identificationServices = {
  fetchIdentifications: body => {
    const options = {
      method: 'POST',
      url: 'identifications',
      data: body,
    };

    return axios(options);
  },

  fetchLastIdentification: body => {
    const options = {
      method: 'POST',
      url: 'identifications?last',
      data: body,
    };

    return axios(options);
  },
};

export default identificationServices;
