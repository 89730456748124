import { makeStyles } from '@material-ui/core/styles';

const spaceWidth = '4px';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 1,
  },
  item: {
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    padding: '1vh 10px',
    maxWidth: 240,
    maxHeight: 201,
    margin: '0 1%',
    marginBottom: '2vh',
  },
  itemTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '110%',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '& svg': {
      width: 30,
      height: 30,
      fill: theme.palette.text.primary,
    },
  },
  gauge: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1vh',
  },
  gaugeSvgWrapper: {
    position: 'relative',
  },
  gaugeSvg: {
    width: 200,
    height: 100,
  },
  emptyGauge: {
    stroke: theme.palette.background.default,
  },
  gaugeStopDefault: {
    stopColor: theme.palette.success.main,
  },
  gaugeStopInfo: {
    stopColor: theme.palette.alert.info,
  },
  gaugeStopCrit: {
    stopColor: theme.palette.alert.crit,
  },
  fillGaugeWithAlarm: {
    stroke: 'url(#linearColors1)',
  },
  fillGaugeDefault: {
    stroke: theme.palette.primary.main,
  },
  value: {
    position: 'absolute',
    bottom: '15%',
    fontSize: '150%',
    fontWeight: 'bold',
    color: '#b7b7b7',
    width: '100%',
    textAlign: 'center',
  },
  unit: {
    fontSize: '70%',
    marginLeft: spaceWidth,
  },
  bottomValues: {
    color: 'rgba(100, 100, 100, 1)',
    borderTop: '1px solid rgba(100, 100, 100, 0.5)',
    paddingTop: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    width: '72%',
  },
  bottomUnit: {
    fontSize: '80%',
    marginLeft: spaceWidth,
  },
  warn: {
    border: `1px solid ${theme.palette.alert.warn}`,
  },
  info: {
    border: `1px solid ${theme.palette.alert.info}`,
  },
  crit: {
    border: `1px solid ${theme.palette.alert.crit}`,
  },
  [theme.breakpoints.down('sm')]: {
    itemTop: {
      fontSize: '90%',
      '& svg': {
        width: 15,
        height: 15,
      },
      '& a': {
        alignSelf: 'flex-start',
      },
    },
    item: {
      maxHeight: 201,
      maxWidth: 150,
    },
    gaugeSvg: {
      width: 150,
      height: 75,
    },
    value: {
      fontSize: '100%',
    },
    bottomValues: {
      fontSize: '0.7rem',
    },
  },
}));

export default useStyles;
