import jwtDecode from 'jwt-decode';

import { LOGOUT, LOGIN } from '../actions/auth';
import { GET_USER, UPDATE_USER } from '../actions/user';

const localId = localStorage.getItem('rubiX-id');
const token = localStorage.getItem('rubiX-token');
const initialDecodeUser = (token && jwtDecode(token)) || null;

const initialState = {
  user: {
    firstname: '',
    lastname: '',
    token,
    _id: localId || (initialDecodeUser && initialDecodeUser._id) || null,
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOGIN}_FULFILLED`: {
      const decodeUser = jwtDecode(action.payload.token);

      return {
        ...state,
        user: {
          ...state.user,
          _id: decodeUser._id,
          token: action.payload.token,
        },
      };
    }

    case LOGOUT:
      return {
        ...state,
        user: {
          firstname: '',
          lastname: '',
        },
      };

    case `${GET_USER}_FULFILLED`:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };

    case `${UPDATE_USER}_FULFILLED`:
      if (action.payload.user._id === state.user._id) {
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload.user,
          },
        };
      }

      return state;

    default:
      return state;
  }
};

export default authReducer;
