import sensors from 'Sensors'; // eslint-disable-line import/no-unresolved

import {
  SELECT_SENSOR,
  SELECT_SENSOR_LIST,
  UNSELECT_SENSOR,
  SELECT_ALL_SENSOR,
  UNSELECT_ALL_SENSOR,
} from '../actions/sensor';
import { FETCH_COMFORTS } from '../actions/comfort';

const types = [...new Set(sensors.map(s => s.type))];

const initialState = {
  list: sensors,
  byName: sensors.reduce((obj, sensor) => {
    obj[sensor.name] = sensor;
    return obj;
  }, {}),
  fullTypeBySensors: sensors.reduce((obj, sensor) => {
    obj[sensor.name] = sensor;
    return obj;
  }, {}),
  typeBySensors: sensors.reduce((obj, sensor) => {
    obj[sensor.name] = sensor.type;
    return obj;
  }, {}),

  types,
  selectedSensorTypes: types,

  sortedTypes: [],

  comforts: [],
};

const sensorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_SENSOR:
      return {
        ...state,
        selectedSensorTypes: [...state.selectedSensorTypes, action.sensor],
      };

    case SELECT_SENSOR_LIST:
      return {
        ...state,
        selectedSensorTypes: action.sensorList,
      };

    case UNSELECT_SENSOR:
      return {
        ...state,
        selectedSensorTypes: state.selectedSensorTypes.filter(
          sensor => sensor !== action.sensor
        ),
      };

    case SELECT_ALL_SENSOR:
      return {
        ...state,
        selectedSensorTypes: state.types,
      };

    case UNSELECT_ALL_SENSOR:
      return {
        ...state,
        selectedSensorTypes: [],
      };

    case `${FETCH_COMFORTS}_FULFILLED`:
      return {
        ...state,
        comforts: action.payload.comforts,
      };

    default:
      return state;
  }
};

export default sensorReducer;
