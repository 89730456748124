import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

import { ThemeProvider } from '@material-ui/core/styles';

import LoginPage from '../login/loginPage/LoginPage';
import createTheme from '../../createTheme';

import './App.scss';

const App = ({ alternative, userAuthenticated, children }) => {
  const [themeType, setThemeType] = useState(
    localStorage.getItem('rubiX-theme') || 'dark'
  );
  const theme = createTheme(themeType);

  const [isTokenValid, setIsTokenValid] = useState();

  useEffect(() => {
    if (alternative === 'mobile') {
      localStorage.setItem('rubiX-theme', 'dark');
      setThemeType('dark');
    } else if (!localStorage.getItem('rubiX-theme')) {
      localStorage.setItem('rubiX-theme', 'dark');
    }
  }, [alternative]);

  useEffect(() => {
    if (userAuthenticated) {
      const parsedJWT = jwtDecode(userAuthenticated);
      const expirationDate = parsedJWT.exp;
      const currentDate = moment().unix();

      if (expirationDate - currentDate < 10) {
        setIsTokenValid(false);
        return;
      }

      setIsTokenValid(true);
    }
  }, [userAuthenticated]);

  return (
    <SnackbarProvider>
      {userAuthenticated && isTokenValid ? (
        children
      ) : (
        <ThemeProvider theme={theme}>
          <LoginPage alternative={alternative} themeType={themeType} />
        </ThemeProvider>
      )}
    </SnackbarProvider>
  );
};

const mapStateToProps = state => ({
  userAuthenticated: state.auth.user.token,
});

export default withRouter(connect(mapStateToProps)(App));
