import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  iconTitle: {
    padding: theme.spacing(0, 0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  iconTitleText: {
    flex: 1,
    paddingLeft: theme.spacing(1),
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  boldFontWeight: {
    fontWeight: 'bold',
  },
  selectedText: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5, 1),
    marginLeft: theme.spacing(0.5),
    borderRadius: '10px',
  },
  iconWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    '& img': {
      width: '90%',
      height: 'auto',
    },
  },
  withBackground: {
    '& svg': {
      width: '50%',
      height: '50%',
      zIndex: 1,
      fill: theme.palette.primary.dark,
    },
    '& img': {
      width: '50%',
      height: '50%',
      zIndex: 1,
      fill: theme.palette.primary.dark,
    },
  },
  iconBackground: {
    display: 'block',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
  },
}));

export default useStyles;
