import React from 'react';

import podIcon from '../../images/devices/pod.png';
import podV2Icon from '../../images/devices/podV2.png';
import wt1Icon from '../../images/devices/wt1.png';
import wsIcon from '../../images/devices/ws.png';

const DeviceIcon = ({ kind, className, style }) => {
  if (kind === 'POD') {
    return (
      <img src={podIcon} alt="pod icon" className={className} style={style} />
    );
  }

  if (kind === 'POD_V2') {
    return (
      <img
        src={podV2Icon}
        alt="pod v2 icon"
        className={className}
        style={style}
      />
    );
  }

  if (kind === 'WT1') {
    return (
      <img src={wt1Icon} alt="wt1 icon" className={className} style={style} />
    );
  }

  if (kind === 'WS') {
    return (
      <img src={wsIcon} alt="ws icon" className={className} style={style} />
    );
  }

  return null;
};

export default DeviceIcon;
