import { FETCH_ALERTS, FETCH_ACTIVE_ALERTS } from '../actions/alert';

const initialState = {
  list: [],
  activeList: [],
  activeByDeviceId: {},

  activeFilteredList: [],
  activeFilteredByDeviceId: {},
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ACTIVE_ALERTS}_FULFILLED`: {
      const alerts = action.payload.data;
      const alertsByDeviceId = alerts.reduce((obj, alert) => {
        const cAlerts = obj[alert.device];
        obj[alert.device] = cAlerts ? [...cAlerts, alert] : [alert];
        return obj;
      }, {});

      return {
        ...state,
        activeList: alerts,
        activeByDeviceId: alertsByDeviceId,

        activeFilteredList: alerts,
        activeFilteredByDeviceId: alertsByDeviceId,
      };
    }

    case `${FETCH_ALERTS}_FULFILLED`:
      return {
        ...state,
        list: action.payload.data,
      };

    default:
      return state;
  }
};

export default alertReducer;
