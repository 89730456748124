import React from 'react';

import { levelAlertName } from '../../../tools/alarmTools';

import DeviceListItem from '../deviceListItem/DeviceListItem';
import useStyles from './styles';

const DeviceList = ({ devices, alertByNodeId, onDeviceClicked }) => {
  const classes = useStyles();

  return (
    <div className={classes.deviceList}>
      {devices.map((device, index) => {
        // Handle alert display
        const alertCtx = alertByNodeId[device._id];
        const alertLevelName = alertCtx && levelAlertName(alertCtx.alertLevel);

        return (
          <DeviceListItem
            key={`device-${index}`}
            alertLevelName={alertLevelName}
            onDeviceClicked={onDeviceClicked}
            device={device}
            alertCtx={alertCtx}
          />
        );
      })}
    </div>
  );
};

export default DeviceList;
