import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  boxInput: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderColor: theme.palette.fieldBorder,
    border: '1px solid',
    borderRadius: 4,
    padding: '2vh 10px',
    margin: '2vh 0',
  },
  boxInputLabel: {
    transform: 'translate(14px, -6px) scale(0.75)',
    top: 0,
    left: 0,
    position: 'absolute',
    zIndex: 1,
    '& > div': {
      padding: '0 10px',
      boxShadow: 'none',
    },
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0.5vh 10px',
  },
  pin: {
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.main,
    display: 'inline-block',
    borderRadius: 5,
    marginRight: theme.spacing(0.5),
  },
  [theme.breakpoints.down('xs')]: {
    list: {
      flexDirection: 'column',
      '& > div': {
        marginTop: theme.spacing(0.5),
      },
    },
  },
}));

export default useStyles;
