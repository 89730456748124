import React from 'react';

import { CircularProgress } from '@material-ui/core';

import useStyles from './styles';

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <CircularProgress size="2rem" thickness={4} />
    </div>
  );
};

export default Loader;
