import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export default useStyles;
