import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  usersInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    opacity: 0.5,
  },
});

export default useStyles;
