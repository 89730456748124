import React, { useState } from 'react';

import { Translate, I18n } from 'react-redux-i18n';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { retriveLang, retriveFlag } from '@common/tools/languageTools';

import '@common/images/flags/famfamfam-flags.css';

import useStyles from './styles';

const LanguageSelectionMenu = ({
  open,
  setOpen,
  defaultLocale,
  acceptedLanguages,
  updateDefaultLocale,
}) => {
  const classes = useStyles();

  const [locale, setLocale] = useState(defaultLocale);

  const handleConfirm = () => {
    updateDefaultLocale(locale);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onBackdropClick={() => setOpen(false)} fullWidth>
      <DialogTitle>
        <Translate value="header.settings.locale.title" />
      </DialogTitle>

      <DialogContent className={classes.languageSetMenu}>
        <TextField
          name="language"
          margin="dense"
          variant="outlined"
          label={I18n.t('header.settings.locale.language')}
          select
          fullWidth
          value={locale}
          onChange={event => setLocale(event.target.value)}
        >
          {acceptedLanguages.map((language, index) => (
            <MenuItem key={`comfort-${index}`} value={language}>
              <i
                className={`famfamfam-flag-${retriveFlag(language)} flag ${
                  classes.languageFlag
                }`}
              />
              {retriveLang(language)}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" size="small" onClick={() => handleCancel()}>
          <Translate value="global.cancel" />
        </Button>

        <Button color="primary" size="small" onClick={() => handleConfirm()}>
          <Translate value="global.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LanguageSelectionMenu;
