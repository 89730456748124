const initialState = {
  stateList: [],
};

const apiReducer = (state = initialState, action) => {
  if (/^.*PENDING$/.test(action.type)) {
    return {
      ...state,
      stateList: [...state.stateList, action.type],
    };
  }

  if (/^.*FULFILLED$/.test(action.type)) {
    return {
      ...state,
      stateList: [...state.stateList, action.type],
    };
  }

  return state;
};

export default apiReducer;
