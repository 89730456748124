import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loginForm: {
    backgroundColor: theme.palette.secondary.backgroundColor,
    padding: theme.spacing(3.5),
    maxWidth: '400px',
    width: '80%',
  },

  forgotPasswordLink: {
    display: 'flex',
    cursor: 'pointer',
    fontSize: '110%',
    margin: '1vh 5px',
    color: theme.palette.secondary.color,
  },

  loginButton: {
    width: '100%',
    marginTop: '1vh',
  },

  loginError: {
    textAlign: 'center',
    margin: '1vh 0',
    color: theme.palette.error.light,
    padding: '10px',
    borderRadius: '5px',
  },

  [theme.breakpoints.down('sm')]: {
    loginForm: {
      padding: theme.spacing(1),
      margin: theme.spacing(0, 1),
    },

    responsiveInput: {
      padding: theme.spacing(1, 1),
    },
  },
}));

export default useStyles;
