import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'auto',
    height: '100%',
  },
  listContainer: {
    padding: theme.spacing(2, 0),
  },
  separator: {
    // so the devicesList do not render under expand item
    marginTop: 57,
  },
  noDevice: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
