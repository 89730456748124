import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  controlContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: theme.spacing(0, 2),
    zIndex: 2,
  },
  root: {
    minWidth: 110,
    maxWidth: 120,
  },
  filled: {
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(0.5, 1),
    fontSize: '0.8rem',
  },
  underline: {
    border: 'none',
    '&::before': {
      content: 'none',
    },
    '&::after': {
      content: 'none',
    },
  },
  inputSelect: {
    borderRadius: 4,
    '& svg': {
      fill: theme.palette.secondary.contrastText,
    },
  },
  subHeader: {
    backgroundColor: theme.palette.background.default,
    borderBottom: theme.palette.divider,
    lineHeight: 3,
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flex: 1,
  },
}));

export default useStyles;
