import React, { useState } from 'react';

import { Translate } from 'react-redux-i18n';

import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Switch from '@material-ui/core/Switch';

import AlternativeVersion from '@common/components/alternativeVersion/AlternativeVersion';
import UserInfo from '@common/components/userInfo/UserInfo';
import { retriveFlag } from '@common/tools/languageTools';
import SignOutIcon from '@common/images/controls/logout.svg';

import packageJson from '@root/package.json';

import '@common/images/flags/famfamfam-flags.css';

import LanguageSelectionMenu from '../languageSelection/LanguageSelection';
import ConfirmLogout from '../confirmLogout/ConfirmLogout';
import UserSettingsMenu from '../userSettingsMenu/UserSettingsMenu';

import useStyles from './styles';

const Settings = ({
  open,
  setOpen,
  user,
  themeType,
  setThemeType,
  defaultLocale,
  acceptedLanguages,
  updateDefaultLocale,
  signOut,
  editUserSettings,
}) => {
  const classes = useStyles();

  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false);

  const [openUserSettings, setOpenUserSettings] = useState(false);

  const toggleTheme = () => {
    if (themeType === 'light') {
      setThemeType('dark');
      localStorage.setItem('rubiX-theme', 'dark');
    } else {
      setThemeType('light');
      localStorage.setItem('rubiX-theme', 'light');
    }
  };

  const handleSaveUserSettings = userSettings => {
    editUserSettings(userSettings);
    setOpenUserSettings(false);
  };

  return (
    <Dialog fullWidth open={open} onBackdropClick={() => setOpen(false)}>
      <DialogTitle>
        <UserInfo user={user} openSettings={setOpenUserSettings} />
      </DialogTitle>

      <DialogContent className={classes.dialogSettingsMenu}>
        <IconButton
          className={classes.settingsButton}
          onClick={() => setLanguageMenuOpen(true)}
        >
          <i
            className={`famfamfam-flag-${retriveFlag(defaultLocale)} flag ${
              classes.languageFlag
            }`}
          />
          <Translate
            className={classes.pageText}
            value="header.settings.locale.setLocale"
          />
        </IconButton>

        <div className={classes.themeSwitch}>
          <Translate value="global.theme.dark" />
          <Switch
            checked={themeType === 'light'}
            onChange={() => toggleTheme()}
          />
          <Translate value="global.theme.light" />
        </div>

        <div className={classes.alternativeLink}>
          <AlternativeVersion alternative="web" />
        </div>

        <IconButton
          className={classes.settingsButton}
          onClick={() => setConfirmLogoutOpen(true)}
        >
          <SignOutIcon className={classes.logoutIcon} />
          <div className={classes.pageText}>
            {' '}
            <Translate value="logout.title" />{' '}
          </div>
        </IconButton>

        <div className={classes.version}>v{packageJson.version}</div>

        {languageMenuOpen && (
          <LanguageSelectionMenu
            open={languageMenuOpen}
            setOpen={setLanguageMenuOpen}
            defaultLocale={defaultLocale}
            acceptedLanguages={acceptedLanguages}
            updateDefaultLocale={updateDefaultLocale}
          />
        )}

        {openUserSettings && (
          <UserSettingsMenu
            open={openUserSettings}
            setOpen={setOpenUserSettings}
            user={user}
            saveUserSettings={handleSaveUserSettings}
          />
        )}

        {confirmLogoutOpen && (
          <ConfirmLogout
            confirmOpen={confirmLogoutOpen}
            setConfirmOpen={setConfirmLogoutOpen}
            signOut={signOut}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Settings;
