import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  languageSetMenu: {
    width: '100%',
  },

  languageFlag: {
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
