import { useEffect, useState } from 'react';

import moment from 'moment';

import {
  buildTick,
  buildTickValues,
  buildTimesForMobile,
  getStep,
} from '@common/tools/chartTools';

export const useXTick = (start, end) => {
  const [tick, setTick] = useState(buildTick(start, end));
  const [times, setTimes] = useState(buildTimesForMobile(start, end, tick));

  useEffect(() => {
    const newTick = buildTick(start, end);

    setTick(newTick);
    setTimes(buildTimesForMobile(start, end, newTick));
  }, [start, end]);

  return {
    tick,
    times,
  };
};

export const useLineChart = (data, sensor, alarms) => {
  const [min, setMin] = useState(Number.MAX_VALUE);
  const [max, setMax] = useState(Number.NEGATIVE_INFINITY);
  const [lineData, setLineData] = useState([]);
  const [tickValues, setTickValues] = useState([]);

  useEffect(() => {
    let tempMin = Number.MAX_VALUE;
    let tempMax = Number.NEGATIVE_INFINITY;
    const tempChartData = [];

    data.forEach(d => {
      const time = moment(d.date).unix() * 1000;
      let value = d[sensor.key];

      if (value) {
        value = parseFloat(value);
        tempMin = Math.min(tempMin, value);
        tempMax = Math.max(tempMax, value);

        tempChartData.push({ x: time, y: value });
      }
    });

    if (tempMin === Number.MAX_VALUE) {
      tempMin = 0;
    }

    if (tempMax === Number.NEGATIVE_INFINITY) {
      tempMax = 100;
    } else {
      if (tempMin >= 0) {
        tempMin = 0;
      }

      const pad = getStep(tempMax - tempMin);
      if (tempMax % pad < pad && tempMax % pad !== 0) {
        tempMax += pad - (tempMax % pad);
      }
    }

    if (tempMin > 2) {
      Math.floor(tempMin);
    } else {
      tempMin.toFixed(2);
    }

    if (tempMax > 2) {
      Math.ceil(tempMax);
    } else {
      tempMax.toFixed(2);
    }

    if (alarms) {
      const limitMin = [tempMin];
      const limitMax = [tempMax];

      alarms.forEach(a => {
        if (a.invert) {
          limitMin.push(a.limits.crit);
          limitMax.push(a.limits.info * 1.1);
        } else {
          limitMax.push(a.limits.crit * 1.1);
          limitMin.push(a.limits.info);
        }
      });

      tempMin = Math.min(...limitMin);
      tempMax = Math.max(...limitMax);
    }

    setMin(Math.round(tempMin * 100) / 100);
    setMax(Math.round(tempMax * 100) / 100);
    setLineData(tempChartData);
    setTickValues(buildTickValues(tempMin, tempMax));
  }, [data, alarms, sensor.key]);

  return {
    min,
    max,
    lineData,
    tickValues,
  };
};

export const useBarChart = (data, sensor, start, end, tickValues) => {
  const [barData, setBarData] = useState([]);

  useEffect(() => {
    const bars = [];
    const datas = data[sensor.key] ? data[sensor.key] : [];

    Object.keys(datas).forEach((category, idx) => {
      const categoryData = datas[category];

      if (categoryData.length < 1) {
        return;
      }

      categoryData.forEach(rect => {
        rect.x = rect.time + ((end - start) / tickValues.length / 2) * 0.5;
        rect.x0 = rect.time - ((end - start) / tickValues.length / 2) * 0.5;
      });

      bars.push({
        key: `bar-${idx}`,
        data: categoryData,
        color: categoryData[0] && categoryData[0].color,
      });
    });

    setBarData(bars);
  }, [data, sensor.key, start, end, tickValues.length]);

  return { barData };
};
