import axios from './axios';

const analysisServices = {
  fetch: analysisOptions => {
    const options = {
      method: 'POST',
      url: 'analysis/data',
      data: analysisOptions,
    };

    return axios(options);
  },
  fetchLast: body => {
    const options = {
      method: 'POST',
      url: 'analysis/data?last',
      data: body,
    };

    return axios(options);
  },
};

export default analysisServices;
