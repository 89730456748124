import React, { useState } from 'react';

import { I18n, Translate } from 'react-redux-i18n';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import useStyles from './styles';

const LoginForm = ({ handleLogin, setForgottenPassword, error }) => {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    handleLogin(event, username, password);
  };

  return (
    <form className={classes.loginForm} onSubmit={handleSubmit}>
      <div>
        <TextField
          name="username"
          fullWidth
          margin="normal"
          variant="outlined"
          label={I18n.t('login.form.label.login')}
          required
          onChange={event => setUsername(event.target.value)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ className: classes.responsiveInput }}
        />

        <TextField
          name="password"
          fullWidth
          margin="normal"
          variant="outlined"
          label={I18n.t('login.form.label.password')}
          required
          type="password"
          onChange={event => setPassword(event.target.value)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ className: classes.responsiveInput }}
        />

        {error && (
          <div className={classes.loginError}>
            <Translate value="login.error" />
          </div>
        )}

        <p
          className={classes.forgotPasswordLink}
          onClick={() => setForgottenPassword(true)}
        >
          <Translate value="login.forgotPassword" />
        </p>

        <Button className={classes.loginButton} type="submit">
          <Translate value="login.login" />
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
