import React from 'react';

import { useHistory } from 'react-router';
import { Translate } from 'react-redux-i18n';

import Dashboard from '@common/components/dashboard/Dashboard';

import DeviceNameNavigation from '@common/components/deviceNameNavigation/DeviceNameNavigation';

import useStyles from './styles';

const GaugesPage = ({ device, setDevice, themeType }) => {
  const history = useHistory();
  const classes = useStyles();

  const handleGoBack = () => {
    setDevice(null);
    history.push('/');
  };

  if (!device || device.sensorChildren.length === 0) {
    return (
      <div className={classes.noSensor}>
        <Translate value="page.navigator.sites.noSensors" />
      </div>
    );
  }

  return (
    <Dashboard
      deviceId={device._id}
      themeType={themeType}
      navigationHeader={() => (
        <DeviceNameNavigation device={device} onClick={handleGoBack} />
      )}
    />
  );
};

export default GaugesPage;
