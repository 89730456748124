import React from 'react';

import clsx from 'clsx';
import { Translate } from 'react-redux-i18n';

import DeviceIcon from '../DeviceIcon';
import useStyles from './styles';

const DeviceListItem = ({
  alertLevelName,
  onDeviceClicked,
  device,
  alertCtx,
  wrapperClassName,
  wrapperStyle = {},
  iconClassName,
  iconStyle = {},
  size = 'normal',
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.item,
        alertLevelName && classes[alertLevelName],
        wrapperClassName
      )}
      style={wrapperStyle}
      onClick={() => onDeviceClicked(device)}
    >
      <DeviceIcon
        className={clsx(classes.icon, iconClassName)}
        kind={device.tech_specs.kind}
        style={iconStyle}
      />

      <div className={classes.description}>
        <div
          className={classes.name}
          style={
            size === 'small' ? { fontSize: 12, marginBottom: '0.5vh' } : {}
          }
        >
          {device.name}
        </div>

        <div
          className={classes.ref}
          style={size === 'small' ? { fontSize: 10 } : {}}
        >
          Ref: {device.reference}
        </div>

        {alertLevelName && (
          <div className={classes.alerts}>
            {alertCtx.alertCount}{' '}
            <Translate value="page.navigator.sites.activeAlerts" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeviceListItem;
