import React, { useState, useEffect, useCallback, useMemo } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import { fetch } from '@common/redux/actions/analysis';
import { fetchIdentifications } from '@common/redux/actions/identification';

import useUpdateEffect from '@common/hooks/use-update-effect';
import { getAlarmOfSensor } from '@common/tools/alarmTools';

import { I18n } from 'react-redux-i18n';
import Loader from '@components/loader/Loader';
import Chart from '@components/analysis/charts/Chart';
import AnalysisControls from '@components/analysis/analysisControls/AnalysisControls';

import useAnalysisData from '@hooks/use-analysis-data';

import useStyles from './styles';

const findSensorById = (device, sensorId) => {
  let sensor;

  device.sensorChildren.forEach(category => {
    category.children.forEach(s => {
      if (s._id === sensorId) {
        sensor = {
          key: `${device._id}-${s.name}`,
          ...s,
        };
      }
    });
  });

  if (sensorId === 'identification') {
    const ident = device.sensorChildren.find(
      elem => elem.name === 'identification'
    );
    sensor = ident && {
      key: `${device._id}-${ident.children[0].name}`,
      ...ident.children[0],
    };
  }

  return sensor;
};

const AnalysisPage = ({
  devices,
  selectedDevice,
  sensorsByName,
  alarms,
  fetchData,
  fetchIdentificationData,
}) => {
  const location = useLocation();
  const queryParms = new URLSearchParams(location.search);
  const sensorId = queryParms.get('sensor');
  const classes = useStyles();

  const [sensor, setSensor] = useState(
    selectedDevice && findSensorById(selectedDevice, sensorId)
  );
  const [sensorAlarms, setSensorAlarms] = useState(null);
  const [device, setDevice] = useState(selectedDevice);

  const {
    realtime,
    setRealtime,
    data,
    setData,
    loading,
    odorCategories,
    start,
    setStart,
    end,
    setEnd,
  } = useAnalysisData({
    fetchData,
    fetchIdentificationData,
    device,
    sensor,
  });

  // hack to add a play / pause character in daterangepicker according to realtime status
  useEffect(() => {
    const realtimeRange = document.querySelector(
      `li[data-range-key="${I18n.t('global.form.inputs.datePicker.realtime')}"]`
    );

    if (realtime) {
      realtimeRange.classList.remove('realtime-paused');
      realtimeRange.classList.add('realtime-running');
    } else {
      realtimeRange.classList.add('realtime-paused');
      realtimeRange.classList.remove('realtime-running');
    }
  }, [realtime]);

  useUpdateEffect(() => {
    // reset sensor for new selected device;
    const newSensor = device.sensors_list.find(s => s.ts === sensor.ts);
    const tempSensor = device.sensors_list.find(s => s.ts === 'temperature');

    if (newSensor) {
      setSensor({
        key: `${device._id}-${newSensor.name}`,
        ...newSensor,
      });
    } else if (tempSensor) {
      setSensor({
        key: `${device._id}-${tempSensor.name}`,
        ...tempSensor,
      });
    } else {
      setSensor('');
    }

    setData([]);
  }, [device]);

  useEffect(() => {
    if (!device) {
      return;
    }

    let currentSensorAlarms = getAlarmOfSensor(alarms, device, sensor);

    // do not display alarms if there are more than 2
    if (currentSensorAlarms > 2) {
      currentSensorAlarms = null;
    }

    // if 2 alarms, only display if one is invert and not the other one.
    if (currentSensorAlarms === 2) {
      if (
        currentSensorAlarms.every(a => a.invert === true) ||
        currentSensorAlarms.every(a => a.invert === false)
      ) {
        currentSensorAlarms = null;
      }
    }

    if (currentSensorAlarms.lenght < 1) {
      currentSensorAlarms = null;
    }

    setSensorAlarms(currentSensorAlarms);
  }, [sensor, alarms, device]);

  const handleSelectSensor = useCallback(
    event => {
      const id = event.target.value;
      let selectedSensor;

      device.sensorChildren.forEach(category => {
        category.children.forEach(s => {
          if (s._id === id) {
            selectedSensor = s;
          }
        });
      });

      if (selectedSensor) {
        const precision =
          sensorsByName[selectedSensor.ts] &&
          sensorsByName[selectedSensor.ts].precisi1on;
        const unit =
          sensorsByName[selectedSensor.ts] &&
          sensorsByName[selectedSensor.ts].unit;

        selectedSensor = {
          ...selectedSensor,
          key: `${device._id}-${selectedSensor.name}`,
          unit: unit || '',
          precision: precision || '0.00',
        };

        setSensor(selectedSensor);
        setData([]);
      }
    },
    [device._id, device.sensorChildren, sensorsByName, setData]
  );

  const handleSwapPeriod = useCallback(
    way => {
      const diff = moment.duration(end.diff(start));

      switch (way) {
        case 'next':
          setStart(s => moment(s).add(diff));
          setEnd(e => moment(e).add(diff));
          setData([]);

          break;

        case 'prev':
          setStart(s => moment(s).subtract(diff));
          setEnd(e => moment(e).subtract(diff));
          setData([]);

          break;

        default:
          break;
      }
    },
    [start, end, setStart, setEnd, setData]
  );

  const handleDateRangeApplied = useCallback(
    (event, picker) => {
      if (
        picker.chosenLabel.includes(
          I18n.t('global.form.inputs.datePicker.realtime')
        )
      ) {
        setRealtime(r => !r);
      } else {
        setRealtime(false);
      }

      setStart(picker.startDate);
      setEnd(picker.endDate);
      setData([]);
    },
    [setStart, setEnd, setRealtime, setData]
  );

  const norender = useMemo(() => !selectedDevice && !sensor, [
    selectedDevice,
    sensor,
  ]);

  return norender ? null : (
    <div className={classes.container}>
      <div
        id="dateRangePicker"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      />
      <AnalysisControls
        devices={devices}
        device={device}
        sensor={sensor}
        realtime={realtime}
        start={start}
        end={end}
        handleSwapPeriod={handleSwapPeriod}
        onDateRangeApplied={handleDateRangeApplied}
        setDevice={setDevice}
        onSelectSensor={handleSelectSensor}
        datePickerContainer="#dateRangePicker"
      />

      {loading ? (
        <Loader />
      ) : (
        <Chart
          data={data}
          sensor={sensor}
          start={start}
          end={end}
          alarms={sensorAlarms}
          odorCategories={odorCategories}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  sensorsByName: state.sensor.byName,
  alarms: state.alarm.list,
});

const mapDispatchToProps = dispatch => ({
  fetchData: params => dispatch(fetch(params)),
  fetchIdentificationData: params => dispatch(fetchIdentifications(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisPage);
