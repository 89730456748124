import axios from './axios';

const userServices = {
  create: user => {
    const options = {
      method: 'POST',
      url: 'users',
      data: user,
    };

    return axios(options);
  },

  update: (userId, user) => {
    const options = {
      method: 'PATCH',
      url: `users/${userId}`,
      data: user,
    };

    return axios(options);
  },

  delete: userId => {
    const options = {
      method: 'PATCH',
      url: `users/${userId}/delete`,
    };

    return axios(options);
  },

  get: userId => {
    const options = {
      method: 'GET',
      url: `users/${userId}`,
    };

    return axios(options);
  },

  fetch: () => {
    const options = {
      method: 'GET',
      url: 'users',
    };

    return axios(options);
  },
};

export default userServices;
