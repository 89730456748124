import identificationServices from '../../services/identification';

export const FETCH_IDENTIFICATIONS = 'FETCH_IDENTIFICATIONS';
export const FETCH_LAST_IDENTIFICATION = 'FETCH_LAST_IDENTIFICATION';

export function fetchIdentifications(options) {
  return {
    type: FETCH_IDENTIFICATIONS,
    payload: identificationServices.fetchIdentifications(options),
  };
}

export function fetchLastIdentification(options) {
  return {
    type: FETCH_LAST_IDENTIFICATION,
    payload: identificationServices.fetchLastIdentification(options),
  };
}
