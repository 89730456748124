import React, { useState, useCallback, useEffect, useMemo } from 'react';

import clsx from 'clsx';
import {
  SortableTreeWithoutDndContext as SortableTree,
  getVisibleNodeCount,
} from 'react-sortable-tree';

import DragAndDrop from '../DragAndDrop';
import DeviceIcon from '../devices/DeviceIcon';

import IconTitle from '../iconTitle/IconTitle';
import SiteIcon from '../../images/icons/icon-usine.svg';

import 'react-sortable-tree/style.css';

import './SiteTree.css';

const renderTitle = ({
  node,
  nodeSelected,
  parentNodes,
  children,
  onSiteSelected,
  onDeviceSelected,
  renderSite,
  renderDevice,
  renderSensorType,
  renderSensor,
}) => {
  if (node.isDevice) {
    return (
      (renderDevice && renderDevice(node)) || (
        <IconTitle
          title={node.name}
          selected={nodeSelected}
          icon={<DeviceIcon kind={node.tech_specs.kind} />}
          titleStyle={{ fontSize: '0.9rem' }}
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            onDeviceSelected && onDeviceSelected(node);
          }}
        />
      )
    );
  }
  if (node.isSensorType) {
    // Sensor Type
    return (
      (renderSensorType && renderSensorType(node, parentNodes[0])) || node.name
    );
  }
  if (node.isSensor) {
    // Sensor
    return (renderSensor && renderSensor(node, parentNodes[1])) || node.name;
  }

  // Site
  // Custom render element and add click
  return (
    (renderSite &&
      React.cloneElement(renderSite(node, children), {
        onClick: () => onSiteSelected(node),
      })) || (
      <IconTitle
        title={node.name}
        selected={nodeSelected}
        icon={<SiteIcon />}
        titleStyle={{ fontSize: '0.9rem' }}
        onClick={() => onSiteSelected(node)}
      />
    )
  );
};

const SiteTree = ({
  sites,
  showDevices,
  showSensors,
  noVirtual,
  treeLocked,
  useNodeCount,
  selectedNodeIds,
  expandedNodes,
  expandLevel,
  onNodeDrop,
  onSiteSelected,
  onDeviceSelected,
  renderSite,
  renderDevice,
  renderSensorType,
  renderSensor,
  style,
}) => {
  const [treeData, setTreeData] = useState(null);
  const [localExpandedNodes, setLocalExpandedNodes] = useState({});

  const buildTreeNodes = useCallback(
    (nodes, level = 0, parentNodes = []) => {
      const tree =
        nodes &&
        nodes
          .filter(node => {
            if (
              node.isDevice &&
              showDevices !== undefined &&
              showDevices === false
            ) {
              return false;
            } // Do not render devices if not specified

            if ((node.isSensorType || node.isSensor) && !showSensors) {
              return false;
            } // Do not render sensors if not specified

            if (node.isVirtual && noVirtual) {
              return false;
            }

            return true;
          })
          .map(node => {
            // Build children first
            const children = buildTreeNodes(
              node.isDevice ? node.sensorChildren : node.children,
              level + 1,
              [node, ...parentNodes]
            );

            // Do we need to mark this node as selected (Only of tree is locked)
            let nodeSelected = false;

            if (treeLocked) {
              // --> Check if the node is in selectedNode of else if one of its children is in selectedNode
              nodeSelected =
                selectedNodeIds && selectedNodeIds.includes(node._id);
              if (!nodeSelected && children) {
                // Loop over children
                nodeSelected = children.find(child => child.selected);
              }
            }

            // Prepare title based on type (site/device/sensor)

            // Build node object
            return {
              rubixNode: node,
              title: renderTitle({
                node,
                nodeSelected,
                parentNodes,
                children,
                onSiteSelected,
                onDeviceSelected,
                renderSite,
                renderDevice,
                renderSensor,
                renderSensorType,
              }),
              selected: nodeSelected,
              expanded:
                localExpandedNodes[node._id] ||
                (expandedNodes && expandedNodes.includes(node._id)) ||
                (children &&
                  children.find(child => child.expanded) !== undefined) || // One of the child is expanded
                level < expandLevel, // Node was expanded previously or specified expansion level is lower
              children,
            };
          });

      // Store level 0 in state
      if (level === 0) {
        setTreeData(tree);
      }

      return tree;
    },
    [
      showDevices,
      showSensors,
      noVirtual,
      onSiteSelected,
      onDeviceSelected,
      renderSite,
      renderDevice,
      renderSensor,
      renderSensorType,
      expandLevel,
      expandedNodes,
      localExpandedNodes,
      selectedNodeIds,
      treeLocked,
    ]
  );

  useEffect(() => {
    buildTreeNodes(sites);
  }, [sites, buildTreeNodes]);

  const handleMoveNode = ({ nextParentNode, node }) =>
    onNodeDrop(
      node.rubixNode,
      nextParentNode ? nextParentNode.rubixNode : null
    );
  const canDrop = ({ nextParent }) => !nextParent || !nextParent.isDevice;
  const getNodeKey = evt => evt.node.rubixNode._id;
  const handleVisibilityToggle = evt => {
    // We need to save in state which node is expanded to keep full tree expansion integrity over updates
    setLocalExpandedNodes(expnds => {
      // make expandedNodes global
      const newExpnds = { ...expnds };
      newExpnds[evt.node.rubixNode._id] = evt.expanded;
      return newExpnds;
    });
  };

  const count = useMemo(
    () => (treeData && getVisibleNodeCount({ treeData })) || 0,
    [treeData]
  );

  const containerClassName = useMemo(
    () => clsx('siteTree', treeLocked && 'locked'),
    [treeLocked]
  );
  const containerStyle = useMemo(
    () => ({ height: useNodeCount && count * 38, ...style }),
    [useNodeCount, count, style]
  );

  return (
    <div className={containerClassName} style={containerStyle}>
      <DragAndDrop>
        {treeData && (
          <SortableTree
            treeData={treeData}
            onChange={setTreeData}
            onMoveNode={handleMoveNode}
            canDrop={canDrop} // No next parent or next parent is not a device
            rowHeight={treeLocked ? 35 : 45}
            scaffoldBlockPxWidth={25}
            getNodeKey={getNodeKey}
            onVisibilityToggle={handleVisibilityToggle}
          />
        )}
      </DragAndDrop>
    </div>
  );
};

export default SiteTree;
