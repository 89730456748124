import React from 'react';

import moment from 'moment';
import clsx from 'clsx';

import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
} from 'react-vis';

import { limitColors } from '@common/tools/alarmTools';

import { useLineChart } from '@hooks/use-chart';

import 'react-vis/dist/style.css';
import '../reactVis.css';

import useStyles from './styles';

const MAXIMUM_TICK_BEFORE_SLANTING = 10;

const LineChart = ({
  tick,
  times,
  data,
  sensor,
  xDomain,
  alarms,
  start,
  end,
}) => {
  const classes = useStyles();
  const { min, max, tickValues, lineData } = useLineChart(data, sensor, alarms);

  const renderAlarmThreshold = alarm => [
    <LineSeries
      style={{ strokeDasharray: 3, strokeWidth: 0.8 }}
      data={[
        { x: start, y: alarm.limits.info },
        { x: end, y: alarm.limits.info },
      ]}
      color={limitColors.info}
    />,
    <LineSeries
      style={{ strokeDasharray: 3, strokeWidth: 0.8 }}
      data={[
        { x: start, y: alarm.limits.warn },
        { x: end, y: alarm.limits.warn },
      ]}
      color={limitColors.warn}
    />,
    <LineSeries
      style={{ strokeDasharray: 3, strokeWidth: 0.8 }}
      data={[
        { x: start, y: alarm.limits.crit },
        { x: end, y: alarm.limits.crit },
      ]}
      color={limitColors.critical}
    />,
  ];

  return (
    <FlexibleXYPlot
      xDomain={xDomain}
      yDomain={[min, max]}
      margin={{ left: 70, right: 40 }}
    >
      <HorizontalGridLines
        tickValues={tickValues}
        tickTotal={10}
        className={classes.lines}
      />
      <VerticalGridLines tickValues={times} className={classes.lines} />

      <XAxis
        className={clsx(
          times.length > MAXIMUM_TICK_BEFORE_SLANTING && 'slanted-axis',
          classes.lines
        )}
        tickFormat={value => {
          if (tick === 'minutes' || tick === 'hours') {
            return moment(value).format('HH:mm');
          }
          if (tick === 'days') {
            return moment(value).format('MMM. DD');
          }
          return moment(value).format('MMM. YYYY');
        }}
        tickValues={times}
      />

      <YAxis
        className={classes.lines}
        tickValues={tickValues}
        tickTotal={10}
        tickFormat={value => `${value} ${sensor.unit ? sensor.unit : ''}`}
      />

      {lineData.length === 0 && <LineSeries data={[{ x: null, y: null }]} />}

      <LineSeries data={lineData} curve="curveMonotoneX" />

      {alarms && alarms.map(alarm => renderAlarmThreshold(alarm))}
    </FlexibleXYPlot>
  );
};

export default LineChart;
