import axios from './axios';

const comfortServices = {
  fetch: () => {
    const options = {
      method: 'GET',
      url: 'comforts',
    };

    return axios(options);
  },
};

export default comfortServices;
