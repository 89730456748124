import React from 'react';

import moment from 'moment';
import clsx from 'clsx';

import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  VerticalRectSeries,
} from 'react-vis';

import { buildTickValues } from '@common/tools/chartTools';

import { useBarChart } from '@hooks/use-chart';

import 'react-vis/dist/style.css';
import '../reactVis.css';

import useStyles from './styles';

const MAXIMUM_TICK_BEFORE_SLANTING = 10;

export const Legend = ({ legend }) => {
  const classes = useStyles();

  return (
    <div className={classes.legendContainer}>
      {legend.map(item => (
        <div className={classes.legendItem} key={item.color}>
          <div
            className={classes.legendPinItem}
            style={{ backgroundColor: item.color }}
          />
          {item.name}
        </div>
      ))}
    </div>
  );
};

const BarChart = ({ start, end, tick, times, data, sensor, xDomain }) => {
  const classes = useStyles();

  const tickValues = buildTickValues(0, 100);
  const { barData } = useBarChart(data, sensor, start, end, tickValues);

  return (
    <FlexibleXYPlot
      xDomain={xDomain}
      yDomain={[0, 100]}
      margin={{ left: 70, right: 40 }}
      stackBy="y"
    >
      <HorizontalGridLines
        tickValues={tickValues}
        tickTotal={10}
        className={classes.lines}
      />
      <VerticalGridLines tickValues={times} className={classes.lines} />

      <XAxis
        className={clsx(
          times.length > MAXIMUM_TICK_BEFORE_SLANTING && 'slanted-axis',
          classes.lines
        )}
        tickFormat={value => {
          if (tick === 'minutes' || tick === 'hours') {
            return moment(value).format('HH:mm');
          }
          if (tick === 'days') {
            return moment(value).format('MMM. DD');
          }
          return moment(value).format('MMM. YYYY');
        }}
        tickValues={times}
      />

      <YAxis
        className={classes.lines}
        tickTotal={10}
        tickFormat={value => `${value} ${sensor.unit ? sensor.unit : ''}`}
      />

      {barData.length === 0 && (
        <VerticalRectSeries data={[{ x: null, y: null }]} />
      )}

      {barData.map(bar => [
        <VerticalRectSeries key={bar.key} data={bar.data} color={bar.color} />,
      ])}
    </FlexibleXYPlot>
  );
};

export default BarChart;
