import { combineReducers } from 'redux';

import { i18nReducer } from 'react-redux-i18n';

import { connectRouter } from 'connected-react-router';
import apiReducer from './apiReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import siteReducer from './siteReducer';
import sensorReducer from './sensorReducer';
import modelReducer from './modelReducer';
import alarmReducer from './alarmReducer';
import alertReducer from './alertReducer';
import extractionReducer from './extractionReducer';
import calculusReducer from './calculusReducer';

import enhancementReducer from './enhancementReducer';

export default function createRootReducer(history) {
  const combinedReducers = combineReducers({
    api: apiReducer,
    auth: authReducer,
    user: userReducer,

    // Add tree key before going through combinedReducers. It avoid a warning while calling redux/combinedReducers
    tree: (state = {}) => state,

    site: siteReducer,
    sensor: sensorReducer,
    model: modelReducer,
    alarm: alarmReducer,
    alert: alertReducer,
    extraction: extractionReducer,
    calculus: calculusReducer,

    i18n: i18nReducer,

    router: connectRouter(history),
  });

  // Return a root reducer, build with the classic combined Reducers and the treeReducer that uses previous reducer states
  return (state, action) => {
    const intermediateState = combinedReducers(state, action);
    const finalState = enhancementReducer(intermediateState, action);

    return finalState;
  };
}
