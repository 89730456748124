import React, { useState, useEffect, useCallback } from 'react';

import { I18n } from 'react-redux-i18n';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';

import Button from '@material-ui/core/Button';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import IconTitle from '@common/components/iconTitle/IconTitle';
import AlertBox from '@common/components/alertBox/AlertBox';

import SiteIcon from '@common/images/icons/icon-usine.svg';
import SiteTreeWithAlerts from '@components/siteTreeWithAlert/SiteTreeWithAlert';

import useStyles from './styles';

const SiteNavigation = ({
  site,
  devices,
  setDevices,
  onUnSelectSite,
  alertsByNodeId,
}) => {
  const classes = useStyles();
  const [alert, setAlert] = useState(site ? alertsByNodeId[site._id] : null);

  const [expanded, setExpanded] = useState(true);
  const [subSite, setSubSite] = useState(null);

  useEffect(() => {
    setSubSite(null);
    setAlert(site ? alertsByNodeId[site._id] : null);
    setExpanded(true);
  }, [site, alertsByNodeId]);

  useEffect(() => {
    if (devices.length > 0) {
      setExpanded(false);
    }
  }, [devices]);

  const handleSiteSelected = useCallback(
    newSite => {
      setSubSite(newSite);
      setAlert(newSite ? alertsByNodeId[newSite._id] : null);
      setDevices(newSite);
    },
    [alertsByNodeId, setDevices]
  );

  const handleUnSelectSite = useCallback(() => {
    setSubSite(null);
    setAlert(null);
    setExpanded(false);
    onUnSelectSite();
  }, [onUnSelectSite]);

  return (
    <Accordion
      classes={{ root: classes.rootPanel, rounded: classes.roundedPanel }}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandLessIcon />}
        onClick={() => setExpanded(e => !e)}
      >
        <div className={classes.heading}>
          <IconTitle
            wrapperClass={classes.iconWrapper}
            title={subSite ? subSite.name : site && site.name}
            icon={<SiteIcon />}
            withBackground
            titleItem
          />
          {alert && (
            <AlertBox count={alert.alertCount} level={alert.alertLevel} />
          )}
        </div>
      </AccordionSummary>

      {site && site.children && (
        <AccordionDetails className={classes.panelDetail}>
          <SiteTreeWithAlerts
            style={{ overflow: 'auto' }}
            sites={[site] || []}
            treeLocked
            expandLevel={1}
            onSiteSelected={handleSiteSelected}
            selectedSite={subSite || site}
            alertsByNodeId={alertsByNodeId}
          />
        </AccordionDetails>
      )}

      <AccordionActions classes={{ root: classes.rootAction }}>
        <Button color="secondary" size="small" onClick={handleUnSelectSite}>
          {I18n.t('page.navigator.sites.quitSite')}
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

export default SiteNavigation;
