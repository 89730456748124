import React from 'react';

import clsx from 'clsx';

import { levelAlertName } from '../../tools/alarmTools';
import useStyles from './styles';

const AlertBox = ({ count, level }) => {
  const classes = useStyles();
  const levelName = levelAlertName(level);

  return (
    <div className={clsx(classes.alertBox, classes[levelName])}>{count}</div>
  );
};

export default AlertBox;
