import React, { useState } from 'react';
import { connect } from 'react-redux';

import { useSnackbar } from 'notistack';

import { login, forgotPassword } from '../../../redux/actions/auth';
import logoDark from '../../../images/logo-dark.png';
import logoLight from '../../../images/logo-light.png';
import loginBackground from '../../../images/background.jpg';
import loginBackgroundLight from '../../../images/background-light.jpg';

import LoginForm from '../loginForm/LoginForm';
import ForgottenPasswordForm from '../forgottenPasswordForm/ForgottenPasswordForm';
import ForgottenPasswordSent from '../forgottenPasswordSent/ForgottenPasswordSent';

import useStyles from './styles';
import AlternativeVersion from '../../alternativeVersion/AlternativeVersion';

const LoginPage = ({
  themeType,
  alternative,
  sendLogin,
  sendForgotPassword,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [forgottenPasswordEmail, setForgottenPasswordEmail] = useState('');
  const [forgottenPassword, setForgottenPassword] = useState(false);
  const [forgottenPasswordSent, setForgottenPasswordSent] = useState(false);
  const [error, setError] = useState(false);

  const handleLogin = (event, username, password) => {
    event.preventDefault();

    sendLogin(username, password)
      .then(res => {
        localStorage.setItem('rubiX-token', res.value.token);
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 401) {
            setError(true);
          } else {
            setError(false);
            enqueueSnackbar(err.response.data.error, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        } else {
          setError(false);
          enqueueSnackbar(err.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      });
  };

  const handleForgottenPassword = (event, email) => {
    event.preventDefault();

    setForgottenPasswordEmail(email);

    sendForgotPassword(email)
      .then(() => {
        setForgottenPasswordSent(true);
        return setForgottenPassword(false);
      })
      .catch(payload =>
        setError(payload.response.data.result || payload.response.data.error)
      );

    return setForgottenPassword(true);
  };

  return (
    <div
      className={classes.loginPage}
      style={{
        backgroundImage: `url(${
          themeType === 'light' ? loginBackgroundLight : loginBackground
        })`,
      }}
    >
      <img
        src={`${themeType === 'light' ? logoLight : logoDark}`}
        alt="logo"
        className={classes.rubixLogoImage}
      />

      {!forgottenPassword && !forgottenPasswordSent && (
        <LoginForm
          handleLogin={handleLogin}
          setForgottenPassword={setForgottenPassword}
          error={error}
        />
      )}

      {forgottenPassword && (
        <ForgottenPasswordForm
          setForgottenPassword={setForgottenPassword}
          handleForgottenPassword={handleForgottenPassword}
          error={error}
        />
      )}

      {forgottenPasswordSent && (
        <ForgottenPasswordSent
          email={forgottenPasswordEmail}
          setForgottenPassword={setForgottenPassword}
          setForgottenPasswordSent={setForgottenPasswordSent}
        />
      )}

      <div className={classes.alternative}>
        <AlternativeVersion alternative={alternative} />
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  sendLogin: (username, password) => dispatch(login(username, password)),
  sendForgotPassword: email => dispatch(forgotPassword(email)),
});

export default connect(undefined, mapDispatchToProps)(LoginPage);
