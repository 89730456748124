import axios from './axios';

const siteServices = {
  create: site => {
    const options = {
      method: 'POST',
      url: 'sites',
      data: site,
    };

    return axios(options);
  },

  update: (siteId, site) => {
    const options = {
      method: 'PATCH',
      url: `sites/${siteId}`,
      data: site,
    };

    return axios(options);
  },

  saveSiteMap: (siteId, map) => {
    const options = {
      method: 'PATCH',
      url: `sites/${siteId}`,
      data: { map },
    };

    return axios(options);
  },

  deleteSiteMap: siteId => {
    const options = {
      method: 'PATCH',
      url: `sites/${siteId}`,
      data: { map: null },
    };

    return axios(options);
  },

  delete: siteId => {
    const options = {
      method: 'PATCH',
      url: `sites/${siteId}/delete`,
    };

    return axios(options);
  },

  fetchSites: () => {
    const options = {
      method: 'GET',
      url: 'sites',
    };

    return axios(options);
  },

  getSite: (siteId, withMap) => {
    const options = {
      method: 'GET',
      url: `sites/${siteId}${withMap ? '?map' : ''}`,
    };

    return axios(options);
  },
};

export default siteServices;
