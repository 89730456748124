import React from 'react';
import { useSelector } from 'react-redux';

import { SensorItem } from '../sensors/sensors/Sensors';

import TemperatureIcon from '../../images/sensors/icon-temperature.svg';

const DashboardSensor = ({
  sensor,
  index,
  valuesBySensorsType,
  device,
  alarms,
  categoryName,
}) => {
  const sensorByNames = useSelector(state => state.sensor.byName);
  const activeAlerts = useSelector(state =>
    state.alert.activeByDeviceId[device._id]
      ? state.alert.activeByDeviceId[device._id]
      : []
  );
  // Retrieve full sensor object
  const fullSensor = sensorByNames[sensor.ts];

  // eslint-disable-next-line no-shadow
  const alarm = alarms.find(alarm => alarm.type === sensor.ts);

  let alerts;
  if (alarm) {
    alerts = activeAlerts.filter(alert => alert.alarm === alarm._id);
  } else {
    alerts = activeAlerts.filter(alert => alert.type === sensor.ts);
  }

  // Retrieve data values
  const value = valuesBySensorsType[`${device._id}-${sensor.name}`];

  // Determine min/max
  const min = fullSensor ? fullSensor.limits.min : 0;
  const max = fullSensor ? fullSensor.limits.max : 100;

  return (
    <SensorItem
      key={`sensor_${index}_${sensor.ts}`}
      icon={<TemperatureIcon />}
      type={sensor.ts}
      min={min}
      max={max}
      value={value || min}
      unit={fullSensor ? fullSensor.unit : ''}
      alarm={alarm}
      alertLevel={alerts.reduce(
        (level, alert) => Math.max(level, alert.level),
        0
      )}
      deviceId={device._id}
      siteId={device.site_id}
      sensorId={sensor._id}
      tooltip={categoryName}
    />
  );
};

export default DashboardSensor;
