import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  deviceList: {
    flex: 1,
    display: 'flex',
    flexFlow: 'wrap',
    overflowY: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    deviceList: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
  },
}));

export default useStyles;
