import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dialogSettingsMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
  },
  title: {
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.secondary.color,
  },
  pageText: {
    fontSize: '1rem',
    color: theme.palette.secondary.contrastText,
  },
  languageFlag: {
    marginRight: '1vh',
  },
  settingsButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
  },
  themeSwitch: {
    width: '100%',
    padding: theme.spacing(0, 1.5),
    color: theme.palette.secondary.contrastText,
  },
  alternativeLink: {
    color: theme.palette.secondary.contrastText,
    '& a': {
      padding: theme.spacing(1.5),
    },
  },
  logoutIcon: {
    maxWidth: '15px',
    maxHeight: '15px',
    marginRight: '10px',
    fill: theme.palette.secondary.contrastText,
  },
  version: {
    width: '100%',
    fontSize: '80%',
    color: theme.palette.secondary.contrastText,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
}));

export default useStyles;
