import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  legendContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: '3px 10px',
    borderRadius: 5,
    marginBottom: theme.spacing(0.5),
  },
  legendPinItem: {
    width: 10,
    height: 10,
    display: 'inline-block',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  lines: {
    '& line': {
      stroke: theme.palette.divider,
      opacity: 0.5,
    },
  },
}));

export default useStyles;
