import authServices from '../../services/auth';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

export function login(user, password) {
  return {
    type: LOGIN,
    payload: authServices.login(user, password),
  };
}

export function logout() {
  localStorage.removeItem('rubiX-token');
  localStorage.removeItem('rubiX-id');

  return {
    type: LOGOUT,
  };
}

export function forgotPassword(email) {
  return {
    type: FORGOT_PASSWORD,
    payload: authServices.forgotPassword(email),
  };
}
