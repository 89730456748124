import React, { useCallback, useMemo } from 'react';

import { Translate, I18n } from 'react-redux-i18n';

import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { limitColors } from '@common/tools/alarmTools';
import useStyles from './styles';

const availableNotifTypes = ['email', 'sms'];

const AlertNotifTypeForm = ({ alertNotif, type, index, name, onChange }) => {
  const handleCheckboxChange = useCallback(
    (event, checked) => onChange({ checked, level: name, type }),
    [name, type, onChange]
  );

  return (
    <FormControlLabel
      key={`${type}-${index}`}
      control={
        <Checkbox
          style={{ padding: '10px' }}
          checked={alertNotif.includes(type)}
          onChange={handleCheckboxChange}
        />
      }
      label={I18n.t(`page.settings.user.form.label.alertNotif.${type}`)}
    />
  );
};

const UserAlertNotif = ({ user, name, onChange }) => {
  const classes = useStyles();

  const alertNotif = useMemo(() => user.alert_notif[name], [user, name]);

  return (
    <div key={name}>
      <div>
        <div
          className={classes.pin}
          style={{
            backgroundColor:
              name === 'crit' ? limitColors.critical : limitColors[name],
          }}
        />
        <Translate value={I18n.t(`page.alarm.limits.${name}`)} />
      </div>

      {availableNotifTypes.map((type, index) => (
        <AlertNotifTypeForm
          alertNotif={alertNotif}
          type={type}
          index={index}
          key={`${type}-${index}`}
          name={name}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

const UserAlertNotifs = ({ user, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.boxInput}>
      <FormLabel className={classes.boxInputLabel}>
        <Paper>
          <Translate value="page.settings.user.form.label.alertNotif.title" />
        </Paper>
      </FormLabel>

      <div className={classes.list}>
        {Object.keys(user.alert_notif).map(key => (
          <UserAlertNotif
            key={key}
            user={user}
            name={key}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
};

export default UserAlertNotifs;
