import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  forgottenPasswordForm: {
    backgroundColor: theme.palette.secondary.backgroundColor,
    padding: theme.spacing(3.5),
    maxWidth: '400px',
    width: '80%',
  },

  sendForgottenPasswordError: {
    textAlign: 'center',
    margin: '1vh 0',
    color: theme.palette.error.light,
    padding: '10px',
    borderRadius: '5px',
  },

  sendEmailButton: {
    width: '100%',
    marginTop: '1vh',
  },

  [theme.breakpoints.down('sm')]: {
    responsiveInput: {
      padding: theme.spacing(1, 1),
    },
    responsiveLabel: {
      transform: 'translate(14px, 10px) scale(1)',
    },
  },
}));

export default useStyles;
