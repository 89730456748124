import React from 'react';

import { I18n } from 'react-redux-i18n';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import UserAlertNotifs from '../userAlertNotifs/UserAlertNotifs';

const UserSettingsForm = ({ user, setEmail, setPhone, setNotifs }) => {
  return (
    <FormGroup>
      <TextField
        fullWidth
        name="email"
        margin="normal"
        variant="outlined"
        label={I18n.t('page.settings.user.form.label.email')}
        required
        error={user.email.length === 0}
        helperText={I18n.t('global.form.requiredField')}
        value={user.email}
        onChange={e => setEmail(e.target.value)}
      />

      <TextField
        fullWidth
        type="tel"
        name="phone"
        margin="normal"
        variant="outlined"
        label={I18n.t('page.settings.user.form.label.phone')}
        value={user.phone}
        onChange={e => setPhone(e.target.value)}
      />

      <UserAlertNotifs user={{ ...user }} onChange={setNotifs} />
    </FormGroup>
  );
};

export default UserSettingsForm;
