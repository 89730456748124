import userServices from '../../services/user';

export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER = 'GET_USER';
export const FETCH_USERS = 'FETCH_USERS';

export function createUser(user) {
  return {
    type: CREATE_USER,
    payload: userServices.create(user),
  };
}

export function updateUser(user) {
  // Remove id if needed
  const userId = user._id;
  delete user._id;

  return {
    type: UPDATE_USER,
    payload: userServices.update(userId, user),
  };
}

export function deleteUser(user) {
  return {
    type: DELETE_USER,
    payload: userServices.delete(user._id),
  };
}

export function getUser(userId = localStorage.getItem('rubiX-id')) {
  return {
    type: GET_USER,
    payload: userServices.get(userId),
  };
}

export function fetchUsers() {
  return {
    type: FETCH_USERS,
    payload: userServices.fetch(),
  };
}
