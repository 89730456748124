import Axios from 'axios';

let jwtToken = localStorage.getItem('rubiX-token');

function handleErrors(e) {
  // eslint-disable-next-line no-console
  console.error('Services error :', e);
}

export default function axios(options, readReponse = false) {
  return new Promise((resolve, reject) => {
    Axios({
      ...options,
      headers: {
        ...options.headers,
        'x-auth': jwtToken,
        'Content-Type': 'application/json', // Each call is JSON type
      },
      url: window.RUBIX_SETTINGS.apiUrl + options.url, // Add Url before service path
    })
      .then(res => {
        // try to get token on login request
        if (options.url === 'login') {
          jwtToken = res.data.token;
        }

        if (readReponse) {
          resolve(res);
        } else {
          resolve(res.data);
        }
      })
      .catch(e => {
        handleErrors(e);
        reject(e);
      });
  });
}
