import deviceServices from '../../services/device';

export const CREATE_DEVICE = 'CREATE_DEVICE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const UPDATE_DEVICE_CONFIGURATION_LOCK =
  'UPDATE_DEVICE_CONFIGURATION_LOCK';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const FETCH_DEVICES = 'FETCH_DEVICES';
export const GET_DEVICE = 'GET_DEVICE';

export function createDevice(device) {
  if (device.tech_specs.kind !== 'POD') {
    delete device.frame_type;
  }

  return {
    type: CREATE_DEVICE,
    payload: deviceServices.create(device),
  };
}

export function updateDevice(device) {
  // Remove id if needed
  const deviceId = device._id;
  delete device._id;

  let deviceFrameType = device.frame_type;

  if (device.tech_specs.kind !== 'POD') {
    delete device.frame_type;
  } else if (device.tech_specs.kind === 'POD' && !deviceFrameType) {
    deviceFrameType = 0;
  }

  // Filter data to post
  const filteredDevice = {
    site_id: device.site_id,
    name: device.name,
    geo_coords: device.geo_coords,
    tech_specs: device.tech_specs,
    model_id: device.model_id,
    sensors_list: device.sensors_list,
    combine_list: device.combine_list,
    identification: device.identification,
    suppression: device.suppression,
    permanent: device.permanent > 0 ? device.permanent : null,
    questionnaire_id: device.questionnaire_id,
    four_twenty: device.four_twenty,
    on_off: device.on_off,
    frame_type: deviceFrameType,
  };

  return {
    type: UPDATE_DEVICE,
    payload: deviceServices.update(deviceId, filteredDevice),
  };
}

export function updateDeviceConfigurationLock(deviceId, locked) {
  return {
    type: UPDATE_DEVICE_CONFIGURATION_LOCK,
    payload: deviceServices.updateConfigurationLock(deviceId, locked),
  };
}

export function deleteDevice(device) {
  return {
    type: DELETE_DEVICE,
    payload: deviceServices.delete(device._id),
  };
}

export function fetchDevices() {
  return {
    type: FETCH_DEVICES,
    payload: deviceServices.fetchDevices(),
  };
}

export function getDevice(deviceId) {
  return {
    type: GET_DEVICE,
    payload: deviceServices.getDevice(deviceId),
  };
}
