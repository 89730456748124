import { useEffect, useState } from 'react';

const useTabIsHidden = defaultValue => {
  const [tabIsHidden, setTabIsHidden] = useState(!!defaultValue);

  useEffect(() => {
    window.onfocus = () => {
      setTabIsHidden(false);
    };
    window.onblur = () => {
      setTabIsHidden(true);
    };
    return () => {
      window.onfocus = null;
      window.onblur = null;
    };
  }, []);

  return tabIsHidden;
};

export default useTabIsHidden;
