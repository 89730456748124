import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0, 0.5, 0),
  },
  secondaryButton: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.dark,
    margin: '0 10px',
    '&:hover': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.light,
    },
    '& svg': {
      fill: theme.palette.secondary.contrastText,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
      padding: theme.spacing(0.5, 1),
    },
  },
  iconButton: {
    padding: theme.spacing(0),
  },
  arowIcon: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    opacity: 1,
    fill: theme.palette.secondary.color,
    [theme.breakpoints.down('sm')]: {
      width: 10,
      height: 10,
    },
  },
  disabledIcon: {
    cursor: 'initial',
    opacity: 0.5,
  },
  calendarIcon: {
    margin: '0 10px 0 5px',
    fill: theme.palette.secondary.contrastText,
    width: 20,
    height: 20,
    [theme.breakpoints.down('sm')]: {
      width: 15,
      height: 15,
    },
  },
}));

export default useStyles;
