import React from 'react';

import clsx from 'clsx';

import useStyles from './styles';

const IconTitle = ({
  title,
  className,
  titleStyle,
  icon,
  style,
  withBackground,
  onClick,
  wrapperClass = '',
  selected,
  titleItem,
}) => {
  const classes = useStyles();

  return (
    <div
      style={style}
      onClick={onClick}
      className={clsx(classes.iconTitle, className)}
    >
      <div
        className={clsx(
          classes.iconWrapper,
          withBackground && classes.withBackground,
          wrapperClass
        )}
      >
        <div className={clsx(withBackground && classes.iconBackground)} />
        {icon}
      </div>

      <span
        className={clsx(
          classes.iconTitleText,
          titleItem && classes.boldFontWeight,
          selected && classes.selectedText
        )}
        style={titleStyle}
      >
        {title}
      </span>
    </div>
  );
};

export default IconTitle;
