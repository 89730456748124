import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  forgotPasswordSent: {
    backgroundColor: theme.palette.secondary.backgroundColor,
    color: theme.palette.secondary.color,
    padding: theme.spacing(3.5),
    maxWidth: '400px',
    width: '80%',
    fontSize: '1rem',
  },

  header1: {
    marginBottom: '2vh',
  },

  sendToLoginButton: {
    width: '100%',
    marginTop: '3vh',
  },

  [theme.breakpoints.down('sm')]: {
    forgotPasswordSent: {
      fontSize: '1em',
    },
  },
}));

export default useStyles;
