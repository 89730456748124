export const limitColors = {
  default: '#72C442',
  info: '#FFDC00',
  warn: '#ED862F',
  critical: '#FF0000',
};

export const ALARM_INFO_LEVEL = 1;
export const ALARM_WARNING_LEVEL = 2;
export const ALARM_CRITICAL_LEVEL = 3;

// Return if the level passed is considered as viewable alert
export const levelIsInAlert = level => level >= ALARM_INFO_LEVEL; // INFO for now

export const levelAlertName = level => {
  switch (level) {
    case 1:
      return 'info';

    case 2:
      return 'warn';

    case 3:
      return 'crit';

    default:
      return undefined;
  }
};

export const maxLevelAlert = alerts =>
  alerts.reduce((level, alert) => Math.max(level, alert.level), 0);

export const buildLimitContext = (alarm, limits) => {
  const range = alarm.limits.crit - alarm.limits.info;
  const pad = Math.abs(range) * 0.2; // 20% of full range

  let ctx = {
    values: [alarm.limits.info, alarm.limits.warn, alarm.limits.crit],
    min: limits ? limits.min : alarm.limits.info - pad,
    max: limits ? limits.max : alarm.limits.crit + pad,
    trackStyle: [
      { backgroundColor: limitColors.default },
      { backgroundColor: limitColors.info },
      { backgroundColor: limitColors.warn },
    ],
    // TODO: Try to create a dynamic property here
    handleStyle: [
      { display: 'none' }, // Hide first handle
      { border: 'none', backgroundColor: 'white' },
      { border: 'none', backgroundColor: 'white' },
      { border: 'none', backgroundColor: 'white' },
    ],
    railStyle: { backgroundColor: limitColors.critical },
  };

  if (alarm.invert) {
    // Invert or change some props of context
    ctx = {
      ...ctx,
      min: limits ? limits.min : alarm.limits.crit - pad,
      max: limits ? limits.max : alarm.limits.info + pad,
      values: ctx.values.reverse(),
      // handleStyle : ctx.handleStyle.reverse(),
      trackStyle: [
        { backgroundColor: limitColors.critical },
        { backgroundColor: limitColors.warn },
        { backgroundColor: limitColors.info },
      ],
      railStyle: { backgroundColor: limitColors.default },
    };
  }

  return ctx;
};

export const getAlarmOfSensor = (alarms, device, sensor) => {
  const modelId = device.model_id;
  const modelAlarms = alarms.filter(alarm => alarm.model_id === modelId);

  return modelAlarms.filter(alarm => alarm.type === sensor.ts);
};
