import React from 'react';

import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import CalendarIcon from '../../images/icons/calendar.svg';
import LeftArrowIcon from '../../images/icons/icon-left-arrow.svg';
import RightArrowIcon from '../../images/icons/icon-right-arrow.svg';

import useStyles from './styles';
import './DatePicker.css';

const DatePicker = ({
  startDate,
  endDate,
  dateRanges,
  onDateRangeApplied,
  swapPeriod,
  timePicker = true,
  timePicker24Hour = true,
  timePickerIncrement,
  singleDatePicker = false,
  dateFormat = 'YYYY-MM-DD HH:mm',
  opens = 'left',
  disabled = false,
  maxDate,
  datePickerContainer,
}) => {
  const classes = useStyles();

  const handleSwapPeriod = way => {
    if (disabled) {
      return;
    }

    swapPeriod(way);
  };

  const button = () => (
    <Button className={classes.secondaryButton} disabled={disabled}>
      <CalendarIcon
        className={clsx(classes.calendarIcon, disabled && classes.disabledIcon)}
      />
      {startDate.format(dateFormat)}{' '}
      {endDate && `/  ${endDate.format(dateFormat)}`}
    </Button>
  );

  return (
    <div className={classes.container}>
      <IconButton
        disabled={disabled}
        className={classes.iconButton}
        onClick={() => handleSwapPeriod('prev')}
      >
        <LeftArrowIcon
          className={clsx(classes.arowIcon, disabled && classes.disabledIcon)}
        />
      </IconButton>

      {disabled ? (
        button()
      ) : (
        <DateRangePicker
          startDate={startDate && startDate.format(dateFormat)}
          endDate={endDate && endDate.format(dateFormat)}
          ranges={dateRanges}
          onApply={onDateRangeApplied}
          timePicker={timePicker}
          timePicker24Hour={timePicker24Hour}
          opens={opens}
          locale={{ format: dateFormat }}
          singleDatePicker={singleDatePicker}
          timePickerIncrement={timePickerIncrement}
          maxDate={maxDate}
          parentEl={datePickerContainer}
        >
          <Button className={classes.secondaryButton}>
            <CalendarIcon className={classes.calendarIcon} />
            {startDate.format(dateFormat)}{' '}
            {endDate && `/  ${endDate.format(dateFormat)}`}
          </Button>
        </DateRangePicker>
      )}

      <IconButton
        disabled={disabled}
        className={classes.iconButton}
        onClick={() => handleSwapPeriod('next')}
      >
        <RightArrowIcon
          className={clsx(classes.arowIcon, disabled && classes.disabledIcon)}
        />
      </IconButton>
    </div>
  );
};

export default DatePicker;
