import moment from 'moment';

export const sortSiteTree = (tree, trad) => {
  const sorted = tree.sort((a, b) => {
    if (a.isSensor && b.isSensor) {
      const aTrad = trad.global.sensors.types[a.ts] || a.ts;
      const bTrad = trad.global.sensors.types[b.ts] || b.ts;

      if (aTrad.toLowerCase() < bTrad.toLowerCase()) {
        return -1;
      }
      if (aTrad.toLowerCase() > bTrad.toLowerCase()) {
        return 1;
      }
      return 0;
    }

    if (a.isSensorType && b.isSensorType) {
      const aTrad = trad.global.sensors.categories[a.name] || a.name;
      const bTrad = trad.global.sensors.categories[b.name] || b.name;

      if (aTrad.toLowerCase() < bTrad.toLowerCase()) {
        return -1;
      }
      if (aTrad.toLowerCase() > bTrad.toLowerCase()) {
        return 1;
      }
      return 0;
    }

    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  sorted.forEach(node => {
    if (node.isDevice) {
      sortSiteTree(node.sensorChildren, trad);
    }
    if (node.children) {
      sortSiteTree(node.children, trad);
    }
  });

  return sorted;
};

export const sortSensorsCategories = (categories, trad) => {
  const sorted = categories.sort((a, b) => {
    let aTrad;
    let bTrad;

    if (typeof a === 'string' && typeof b === 'string') {
      aTrad = trad.global.sensors.categories[a] || a;
      bTrad = trad.global.sensors.categories[b] || b;
    } else {
      aTrad = trad.global.sensors.categories[a.name] || a.name;
      bTrad = trad.global.sensors.categories[b.name] || b.name;
    }

    if (aTrad.toLowerCase() < bTrad.toLowerCase()) {
      return -1;
    }
    if (aTrad.toLowerCase() > bTrad.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return sorted;
};

export const sortSensorsTypes = (sensors, trad) => {
  const sorted = sensors.sort((a, b) => {
    const aTrad = trad.global.sensors.types[a.ts] || a.ts;
    const bTrad = trad.global.sensors.types[b.ts] || b.ts;

    if (aTrad.toLowerCase() < bTrad.toLowerCase()) {
      return -1;
    }
    if (aTrad.toLowerCase() > bTrad.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return sorted;
};

export const sortTypes = (types, trad) => {
  const sorted = types.sort((a, b) => {
    const aTrad = trad.global.sensors.types[a] || a;
    const bTrad = trad.global.sensors.types[b] || b;

    if (aTrad.toLowerCase() < bTrad.toLowerCase()) {
      return -1;
    }
    if (aTrad.toLowerCase() > bTrad.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return sorted;
};

export const sortByNames = items => {
  const sorted = items.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return sorted;
};

export const sortDevices = (devices, trad) => {
  const sorted = sortByNames(devices);
  sorted.forEach(device => {
    device.sensorChildren = sortSensorsCategories(device.sensorChildren, trad);
    device.sensorChildren.forEach(category =>
      sortSensorsTypes(category.children, trad)
    );
  });

  return sorted;
};

export const sortDescByCreationDate = items => {
  const sorted = items.sort((a, b) => {
    if (
      parseInt(moment(a.creationDate).format('x')) <
      parseInt(moment(b.creationDate).format('x'))
    ) {
      return 1;
    }
    if (
      parseInt(moment(a.creationDate).format('x')) >
      parseInt(moment(b.creationDate).format('x'))
    ) {
      return -1;
    }
    return 0;
  });

  return sorted;
};

export const sortUsers = users => {
  const sorted = users.sort((a, b) => {
    if (a.siteName === b.siteName) {
      return a.firstname > b.firstname ? 1 : -1;
    }

    return a.siteName > b.siteName ? 1 : -1;
  });

  return sorted;
};
