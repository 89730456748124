import axios from './axios';

const authServices = {
  login: (user, password) => {
    const options = {
      method: 'POST',
      url: 'login',
      data: {
        login: user,
        password,
      },
    };

    return axios(options);
  },

  forgotPassword: email => {
    const options = {
      method: 'POST',
      url: 'forgottenpassword/generatemail',
      data: {
        mail: email,
      },
    };

    return axios(options);
  },
};

export default authServices;
