import { useEffect, useState, useCallback } from 'react';

import moment from 'moment';

import {
  formatBarSeriesData,
  setOdorCategoriesColors,
} from '@common/tools/identificationTools';

import useError from './use-error';

const useAnalysisData = ({
  fetchData,
  fetchIdentificationData,
  device,
  sensor,
}) => {
  const [realtime, setRealtime] = useState(true);
  const [start, setStart] = useState(moment().subtract(60, 'minutes'));
  const [end, setEnd] = useState(moment());

  const [data, setData] = useState([]);
  const [odorCategories, setOdorCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const { handleError } = useError();

  const getData = useCallback(() => {
    if (sensor === '' || !sensor) {
      return;
    }

    let from = start;
    let to = end;

    if (realtime) {
      from = moment().subtract(60, 'minutes');
      to = moment();
    }

    if (sensor.name !== 'identification') {
      if (!realtime) {
        setLoading(true);
      }

      fetchData({
        from,
        to,
        sources: [device._id],
        types: [sensor.name],
      })
        .then(res => {
          setData(res.value.data);
          setLoading(false);
        })
        .catch(err => {
          handleError(err, false);

          setLoading(false);
        });
    } else {
      if (!realtime) {
        setLoading(true);
      }

      fetchIdentificationData({
        from,
        to,
        sources: [device._id],
      })
        .then(res => {
          const odorCategoriesData = setOdorCategoriesColors(res.value.labels);
          setOdorCategories(odorCategoriesData);

          if (!res.value.data || res.value.data.length === 0) {
            setLoading(false);
            setData([]);
            return;
          }

          setData(formatBarSeriesData(res.value.data, odorCategoriesData));
          setLoading(false);
        })
        .catch(err => {
          handleError(err, false);

          setLoading(false);
        });
    }
  }, [
    device._id,
    end,
    fetchData,
    fetchIdentificationData,
    handleError,
    realtime,
    sensor,
    start,
  ]);

  useEffect(() => {
    let intervalId;

    if (realtime) {
      intervalId = setInterval(() => {
        setStart(moment().subtract(60, 'minutes'));
        setEnd(moment());
        getData();
      }, 15000);
    } else {
      clearInterval(intervalId);
    }

    getData();

    return () => clearInterval(intervalId);
  }, [realtime, end, sensor, getData]);

  return {
    realtime,
    setRealtime,
    start,
    setStart,
    end,
    setEnd,
    data,
    setData,
    loading,
    odorCategories,
  };
};

export default useAnalysisData;
