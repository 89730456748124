import React from 'react';

import SiteTree from '@common/components/tree/SiteTree';
import IconTitle from '@common/components/iconTitle/IconTitle';
import AlertBox from '@common/components/alertBox/AlertBox';

import { levelIsInAlert } from '@common/tools/alarmTools';
import SiteIcon from '@common/images/icons/icon-usine.svg';

const SiteTreeWithAlerts = ({
  sites,
  onSiteSelected,
  selectedSite,
  alertsByNodeId,
  expandLevel,
  style,
}) => (
  <SiteTree
    style={style}
    sites={sites}
    treeLocked
    expandLevel={expandLevel}
    onSiteSelected={onSiteSelected}
    selectedNodeIds={[selectedSite && selectedSite._id]}
    useNodeCount
    showSensors={false}
    showDevices={false}
    renderSite={(node, children) => {
      const alertCtx = alertsByNodeId[node._id];
      let nodeSelected = (selectedSite && selectedSite._id) === node._id;
      if (!nodeSelected && children) {
        nodeSelected = children.find(child => child.selected);
      }
      return (
        <div
          className="iconTitle"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <IconTitle
            title={node.name}
            icon={<SiteIcon />}
            selected={nodeSelected}
          />

          {alertCtx && levelIsInAlert(alertCtx.alertLevel) ? (
            <AlertBox
              style={{ marginLeft: '20px' }}
              count={alertCtx.alertCount}
              level={alertCtx.alertLevel}
            />
          ) : null}
        </div>
      );
    }}
  />
);

export default SiteTreeWithAlerts;
