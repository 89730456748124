import React, { useState, useCallback } from 'react';

import { Translate } from 'react-redux-i18n';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import UserSettingsForm from '@common/components/userSettingsForm/UserSettingsForm';

import '@common/images/flags/famfamfam-flags.css';

const UserSettingsMenu = ({ open, setOpen, user, saveUserSettings }) => {
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [notifs, setNotifs] = useState({ ...user.alert_notif });

  const handleNotifs = useCallback(
    ({ checked, level, type }) => {
      let alertNotif = notifs[level];

      if (checked) {
        alertNotif.push(type);
      } else {
        alertNotif = alertNotif.filter(_type => _type !== type);
      }

      const alertNotifs = { ...notifs };
      alertNotifs[level] = alertNotif;

      setNotifs(n => ({ ...n, ...alertNotifs }));
    },
    [notifs]
  );

  const handleCancel = useCallback(() => {
    setEmail(user.email);
    setPhone(user.phone);
    setNotifs({ ...user.alert_notif });
    setOpen(false);
  }, [user, setOpen]);

  const handleSave = useCallback(
    () => saveUserSettings({ email, phone, alert_notif: { ...notifs } }),
    [email, phone, notifs, saveUserSettings]
  );

  return (
    <Dialog open={open} onBackdropClick={() => setOpen(false)} fullScreen>
      <DialogTitle>
        <Translate value="header.settings.userSettings.title" />
      </DialogTitle>

      <DialogContent>
        <UserSettingsForm
          user={{ email, phone, alert_notif: { ...notifs } }}
          setEmail={setEmail}
          setPhone={setPhone}
          setNotifs={handleNotifs}
        />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" size="small" onClick={handleCancel}>
          <Translate value="global.cancel" />
        </Button>

        <Button color="primary" size="small" onClick={handleSave}>
          <Translate value="global.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSettingsMenu;
