import siteServices from '../../services/site';

export const CREATE_SITE = 'CREATE_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const DELETE_SITE = 'DELETE_SITE';
export const FETCH_SITES = 'FETCH_SITES';
export const GET_SITE = 'GET_SITE';
export const SAVE_SITE_MAP = 'SAVE_SITE_MAP';
export const DELETE_SITE_MAP = 'DELETE_SITE_MAP';

export function createSite(site) {
  return {
    type: CREATE_SITE,
    payload: siteServices.create(site),
  };
}

export function updateSite(site) {
  // Remove id if needed
  const siteId = site._id;
  delete site._id;

  return {
    type: UPDATE_SITE,
    payload: siteServices.update(siteId, site),
  };
}

export function deleteSite(site) {
  return {
    type: DELETE_SITE,
    payload: siteServices.delete(site._id),
  };
}

export function saveSiteMap(siteId, map) {
  return {
    type: SAVE_SITE_MAP,
    payload: siteServices.saveSiteMap(siteId, map),
  };
}

export function fetchSites() {
  return {
    type: FETCH_SITES,
    payload: siteServices.fetchSites(),
  };
}

export function getSite(siteId, withMap) {
  return {
    type: GET_SITE,
    payload: siteServices.getSite(siteId, withMap),
  };
}

export function deleteSiteMap(siteId) {
  return {
    type: DELETE_SITE_MAP,
    payload: siteServices.deleteSiteMap(siteId),
  };
}
