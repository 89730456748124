import React, { useMemo } from 'react';

import DashboardSensor from './DashboardSensor';

const DashboardCategory = ({
  category,
  valuesBySensorsType,
  device,
  alarms,
}) => {
  const sensors = useMemo(() => category.children, [category.children]);
  return (
    <>
      {category.name !== 'identification' &&
        sensors.map((sensor, index) => (
          <DashboardSensor
            key={`${sensor._id}`}
            sensor={sensor}
            index={index}
            valuesBySensorsType={valuesBySensorsType}
            device={device}
            alarms={alarms}
            categoryName={category.name}
          />
        ))}
    </>
  );
};

export default DashboardCategory;
