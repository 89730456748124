import React from 'react';

import { I18n, Translate } from 'react-redux-i18n';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';

import useStyles from './styles';

// need to forwardRef because it is used in Material-ui Menu component in website Header.
const UserInfo = React.forwardRef(({ user, openSettings }, ref) => {
  const classes = useStyles();

  return (
    <div className={classes.usersInfo} ref={ref}>
      <div>
        {!user.firstname && !user.lastname ? (
          <Translate value="header.unkownUser" />
        ) : (
          `${user.firstname} ${user.lastname}`
        )}
      </div>
      <IconButton
        size="small"
        title={I18n.t('header.settings.userSettings.title')}
        onClick={() => openSettings(true)}
      >
        <SettingsIcon />
      </IconButton>
    </div>
  );
});

export default UserInfo;
