import axios from './axios';

const alertServices = {
  fetchAlerts: params => {
    const options = {
      method: 'POST',
      url: 'alerts',
      data: params,
    };

    return axios(options);
  },

  fetchActiveAlerts: params => {
    const options = {
      method: 'POST',
      url: 'alerts/?active=1',
      data: params,
    };

    return axios(options);
  },
};

export default alertServices;
