import USER_ROLE from 'UserRole';
import USER_RIGHTS from 'UserRights';
import {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  FETCH_USERS,
} from '../actions/user';

const initialState = {
  list: [],
  userRole: USER_ROLE,
  userRights: USER_RIGHTS,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_USER}_FULFILLED`:
      return {
        ...state,
        list: [...state.list, action.payload.user],
      };

    case `${UPDATE_USER}_FULFILLED`:
      return {
        ...state,
        list: state.list.map(user => {
          if (user._id === action.payload.user._id) {
            return action.payload.user;
          }
          return user;
        }),
      };

    case `${DELETE_USER}_FULFILLED`:
      return {
        ...state,
        list: state.list.filter(user => user._id !== action.payload.user._id),
      };

    case `${FETCH_USERS}_FULFILLED`:
      return {
        ...state,
        list: action.payload.users,
      };

    default:
      return state;
  }
};

export default userReducer;
