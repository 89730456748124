import React from 'react';

import { I18n } from 'react-redux-i18n';

import TextField from '@material-ui/core/TextField';

import DatePicker from '@common/components/datePicker/DatePicker';
import dates from '@common/tools/dateRangeTool';

import useStyles from './styles';

const AnalysisControls = ({
  devices,
  device,
  setDevice,
  sensor,
  realtime,
  start,
  end,
  handleSwapPeriod,
  onDateRangeApplied,
  onSelectSensor,
  datePickerContainer,
}) => {
  const classes = useStyles();
  const dateRanges = dates.getDateRanges({ realtime });

  return (
    <div className={classes.controlContainer}>
      <div className={classes.selectContainer}>
        <TextField
          select
          color="secondary"
          size="small"
          margin="dense"
          variant="filled"
          classes={{ root: classes.root }}
          SelectProps={{
            native: true,
            classes: { filled: classes.filled },
          }}
          InputProps={{
            className: classes.inputSelect,
            classes: { underline: classes.underline },
          }}
          value={device._id}
          onChange={e => setDevice(devices.find(d => d._id === e.target.value))}
        >
          {devices.map(option => (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          ))}
        </TextField>

        <TextField
          select
          color="secondary"
          size="small"
          margin="dense"
          variant="filled"
          classes={{ root: classes.root }}
          SelectProps={{
            native: true,
            classes: { filled: classes.filled },
            displayEmpty: true,
            renderValue: value => {
              if (!value) {
                return I18n.t('global.none');
              }
              return I18n.t(`global.sensors.types.${sensor.ts}`);
            },
          }}
          InputProps={{
            className: classes.inputSelect,
            classes: { underline: classes.underline },
          }}
          value={(sensor && sensor._id) || ''}
          onChange={onSelectSensor}
        >
          <option value="">None</option>
          {device &&
            device.sensorChildren.map(category => (
              <optgroup
                label={I18n.t(`global.sensors.categories.${category.name}`)}
              >
                {category.children.map(type => (
                  <option key={type._id} value={type._id}>
                    {I18n.t(`global.sensors.types.${type.ts}`)}
                  </option>
                ))}
              </optgroup>
            ))}
        </TextField>
      </div>

      <DatePicker
        startDate={start}
        endDate={end}
        dateRanges={dateRanges}
        onDateRangeApplied={onDateRangeApplied}
        swapPeriod={handleSwapPeriod}
        opens="left"
        datePickerContainer={datePickerContainer}
      />
    </div>
  );
};

export default AnalysisControls;
