import moment from 'moment';

import { darkBackgroundDefault, lightBackgroundDefault } from '../createTheme';

const color = [
  '#264653',
  '#84bcda',
  '#2a9d8f',
  '#e9c46a',
  '#f4a261',
  '#e76f51',
];

export const setOdorCategoriesColors = categories => {
  const odorCategories = [];

  for (let i = 0; i <= categories.length; i++) {
    categories.forEach(category => {
      if (!odorCategories.find(({ name }) => name === category)) {
        if (category === 'Not identified') {
          odorCategories.push({ name: category, color: '#1c3a13' });
        } else {
          odorCategories.push({ name: category, color: color[i] });
          i++;
        }
      }
    });
  }
  return odorCategories;
};

export const getOdorCategoryColor = (odorCategories, category) => {
  let categoryColor = '#1c3a13';

  odorCategories.forEach(odorCategory => {
    if (odorCategory.name === category) {
      categoryColor = odorCategory.color;
    }
  });

  return categoryColor;
};

export const formatIdentificationDatas = (data, date) => {
  const formatedDatas = [];

  for (let i = 0; i < data.length; i++) {
    const values = data[i].split(':');

    const identification = {
      name: values[0],
      value: values[1],
      date,
    };

    formatedDatas.push(identification);
  }

  return formatedDatas;
};

export const formatChartData = (data, categories, themeType) => {
  const formatedData = [];

  if (!data) {
    return [
      {
        angle0: -Math.PI / 2,
        angle: Math.PI / 2,
        radius: 4,
        radius0: 3,
        color:
          themeType === 'light'
            ? lightBackgroundDefault
            : darkBackgroundDefault,
      },
    ];
  }

  data[0].degree = data[0].value * 180 - 90;

  formatedData.push({
    angle0: -Math.PI / 2,
    angle: data[0].degree * (Math.PI / 180),
    radius: 4,
    radius0: 3,
    color: getOdorCategoryColor(categories, data[0].name),
  });

  for (let i = 1; i < data.length; i++) {
    data[i].degree = data[i].value * 180 + data[i - 1].degree;

    formatedData.push({
      angle0: formatedData[i - 1].angle,
      angle: data[i].degree * (Math.PI / 180),
      radius: 4,
      radius0: 3,
      color: getOdorCategoryColor(categories, data[i].name),
    });

    if (i === data.length - 1) {
      formatedData[i].angle = Math.PI / 2;
    }
  }

  return formatedData;
};

export const formatLegend = (value, categories) => {
  const legend = [];

  for (let i = 0; i < categories.length; i++) {
    legend.push({
      name: categories[i].name,
      value: 0,
      color: categories[i].color,
    });
  }

  if (value) {
    for (let i = 0; i < value.length; i++) {
      legend.forEach(item => {
        if (item.name === value[i].name) {
          item.value = value[i].value;
        }
      });
    }
  }

  return legend;
};

export const formatBarSeriesData = (data, categories) => {
  const formatedData = [];

  for (let i = 0; i < data.length; i++) {
    formatedData.push(
      formatIdentificationDatas(
        Object.values(data[i])[0],
        Object.values(data[i])[1]
      )
    );
  }

  const dataByCategory = {};

  categories.forEach(category => {
    dataByCategory[category.name] = [];
  });

  for (let i = 0; i < formatedData.length; i++) {
    formatedData[i].forEach(element => {
      dataByCategory[element.name].push({
        time: moment(element.date).unix() * 1000,
        y: element.value * 100,
        color: getOdorCategoryColor(categories, element.name),
      });
    });
  }

  const datas = {};
  datas[Object.keys(data[0])[0]] = dataByCategory;

  return datas;
};
