import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import useStyles from './styles';

const DeviceNameNavigation = ({ device, onClick, size = 'small' }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <IconButton size="small" onClick={onClick}>
        <ArrowBackIosIcon fontSize={size} />
      </IconButton>
      {device.name}
    </div>
  );
};

export default DeviceNameNavigation;
