import { createMuiTheme } from '@material-ui/core/styles';

const mainColor = '#6ac4d6';

export const darkBackgroundDefault = '#292d2e';
export const lightBackgroundDefault = '#f2f2f2';

const light = {
  // using the same color for deferent variant is no good practice...
  secondary: {
    main: '#d8d9d9',
    light: '#d8d9d9',
    dark: '#d8d9d9',
    color: '#1d2023',
    contrastText: '#1d2023',
  },
  text: {
    primary: '#003e4a',
    secondary: '#5c5c5c',
    disabled: 'rgba(255, 255, 255, 0.5)',
    hint: 'rgba(255, 255, 255, 0.5)',
    icon: 'rgba(255, 255, 255, 0.5)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  fieldBorder: 'rgba(0, 0, 0, 0.23)',
  background: {
    default: '#f2f2f2',
    paper: '#fff',
    main: '#fff',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: '',
  },
};

const dark = {
  text: {
    primary: '#fff',
    secondary: '#bdbdbd',
    disabled: 'rgba(255, 255, 255, 0.5)',
    hint: 'rgba(255, 255, 255, 0.5)',
    icon: 'rgba(255, 255, 255, 0.5)',
  },
  fieldBorder: 'rgba(255, 255, 255, 0.23)',
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    default: '#292d2e',
    paper: '#131313',
    main: '#000',
  },
  action: {
    active: '#fff',
    hover: 'rgba(255, 255, 255, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(255, 255, 255, 0.16)',
    selectedOpacity: 0.16,
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: '',
  },
};

export default type => {
  const theme = type === 'light' ? light : dark;

  return createMuiTheme({
    palette: {
      type,
      primary: {
        main: mainColor,
        light: '#87cfde',
        dark: '#4a8995',
        contrastText: '#fff',
      },
      secondary: {
        main: '#292f34',
        light: '#30373d',
        dark: '#1d2023',
        color: '#bdbdbd',
        contrastText: '#fff',
      },
      white: '#fff',
      error: {
        main: '#903838',
        light: '#ec3030',
      },
      alert: {
        info: '#ffdc00',
        warn: '#ed862f',
        crit: '#f00',
      },
      success: {
        main: '#1E9600',
      },
      ...theme,
    },
    props: {
      MuiButton: {
        variant: 'contained',
        color: 'primary',
      },
    },
    overrides: {
      MuiAppBar: {
        colorDefault: {
          backgroundColor: type === 'dark' ? '#000' : '#fff',
          color: type === 'dark' ? '#fff' : '#000',
        },
      },
      MuiButton: {
        root: {
          fontWeight: 'bold',
          minWidth: '100px',
        },
        contained: {
          '&:hover': {
            backgroundColor: theme.action.hover,
          },
        },
      },
      MuiCheckbox: {
        root: {
          padding: '0px 5px',
        },
        colorSecondary: {
          color: '#ccc',
          '&$checked': '#ccc',
        },
      },
      MuiRadio: {
        colorSecondary: {
          '&$checked': '#ccc',
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: type === 'dark' ? '#30373D' : '#fff',
          color: type === 'dark' ? '#fff' : '#000',
        },
      },
      MuiDialog: {
        paper: {
          backgroundColor: type === 'dark' ? '#30373D' : '#fff',
        },
      },
      MuiDialogContent: {
        root: {
          position: 'relative',
          color: type === 'dark' ? '#dadada' : '#585858',
        },
      },
      MuiSlider: {
        track: {
          '&$vertical': {
            width: '10px',
          },
        },
        thumb: {
          width: '30px',
          height: '30px',
        },
      },
      MuiSwitch: {
        colorSecondary: {
          '&$checked': {
            color: mainColor,
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: mainColor,
          },
        },
        track: {
          backgroundColor: '#ccc',
        },
      },
      typography: {
        useNextVariants: true,
      },
    },
  });
};
