import extractionServices from '../../services/extraction';

export const CREATE_EXTRACTION = 'CREATE_EXTRACTION';
export const DELETE_EXTRACTION = 'DELETE_EXTRACTION';
export const FETCH_EXTRACTIONS = 'FETCH_EXTRACTIONS';
export const FETCH_PENDING_EXTRACTIONS = 'FETCH_PENDING_EXTRACTIONS';
export const DOWNLOAD_EXTRACTION = 'DOWNLOAD_EXTRACTION';

export function createExtraction(extraction) {
  return {
    type: CREATE_EXTRACTION,
    payload: extractionServices.create(extraction),
  };
}

export function deleteExtraction(extraction) {
  return {
    type: DELETE_EXTRACTION,
    payload: extractionServices.delete(extraction._id),
  };
}

export function fetch() {
  return {
    type: FETCH_EXTRACTIONS,
    payload: extractionServices.fetch(),
  };
}

export function fetchPending() {
  return {
    type: FETCH_PENDING_EXTRACTIONS,
    payload: extractionServices.fetchPending(),
  };
}

export function download(extractionId) {
  return {
    type: DOWNLOAD_EXTRACTION,
    payload: extractionServices.download(extractionId),
  };
}
