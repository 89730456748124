import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '2vh',
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
