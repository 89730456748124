import {
  buildSiteTreeContext,
  enhanceWithAlerts,
  buildSitesWithPlumes,
} from '../../tools/siteTools';
import {
  sortSiteTree,
  sortByNames,
  sortSensorsCategories,
  sortDevices,
  sortTypes,
} from '../../tools/sortTool';

import {
  SELECT_SENSOR,
  UNSELECT_SENSOR,
  SELECT_ALL_SENSOR,
  UNSELECT_ALL_SENSOR,
} from '../actions/sensor';

import {
  CREATE_SITE,
  UPDATE_SITE,
  DELETE_SITE,
  FETCH_SITES,
  GET_SITE,
} from '../actions/site';

import {
  CREATE_DEVICE,
  UPDATE_DEVICE,
  DELETE_DEVICE,
  FETCH_DEVICES,
  GET_DEVICE,
} from '../actions/device';

import { FETCH_PLUMES } from '../actions/plumes';
import { FETCH_MODELS } from '../actions/model';
import { FETCH_ACTIVE_ALERTS } from '../actions/alert';

export const initialState = {
  tree: {
    nodesById: {},
  },
};

const enhancementReducer = (state = initialState, action) => {
  switch (action.type) {
    // Sites
    case `${CREATE_SITE}_FULFILLED`:
    case `${UPDATE_SITE}_FULFILLED`:
    case `${DELETE_SITE}_FULFILLED`:
    case `${FETCH_SITES}_FULFILLED`:
    case `${GET_SITE}_FULFILLED`:

    // Devices
    // eslint-disable-next-line no-fallthrough
    case `${CREATE_DEVICE}_FULFILLED`:
    case `${UPDATE_DEVICE}_FULFILLED`:
    case `${DELETE_DEVICE}_FULFILLED`:
    case `${FETCH_DEVICES}_FULFILLED`:
    case `${GET_DEVICE}_FULFILLED`: {
      const {
        site: { deviceList, siteList, plumesList },
        sensor: { typeBySensors },
        alert,
        sensor: { types: categories, byName: typesCategory },
        i18n: { locale, translations },
      } = state;

      const sortedSites = sortByNames(siteList);
      const sortedDevices = sortByNames(deviceList);

      const ctx = buildSiteTreeContext(
        sortedSites,
        sortedDevices,
        typeBySensors
      ); // Complete tree
      const siteTree = buildSiteTreeContext(sortedSites, [], typeBySensors)
        .tree; // Only site tree
      const deviceTree = buildSiteTreeContext([], sortedDevices, typeBySensors)
        .tree; // Only devices tree
      const sortedSensorsCategories = sortSensorsCategories(
        categories,
        translations[locale]
      );
      const sortedTypes = sortTypes(
        Object.keys(typesCategory),
        translations[locale]
      );

      let { tree } = ctx;

      if (deviceTree.length > 0) {
        tree = sortSiteTree(ctx.tree, translations[locale]);
        sortDevices(deviceTree, translations[locale]);
      }

      const alertByNodeId = enhanceWithAlerts(
        ctx.nodesById,
        alert.activeByDeviceId
      );

      return {
        ...state,
        tree: {
          tree,
          nodesById: ctx.nodesById,
          siteTree,
          deviceTree,
        },
        alert: {
          ...state.alert,

          // Tag nodes in alert
          activeByNodeId: alertByNodeId,
          activeFilteredByNodeId: alertByNodeId,
        },
        sensor: {
          ...state.sensor,
          types: sortedSensorsCategories,
          sortedTypes,
        },
        site: {
          ...state.site,
          deviceList: sortedDevices,
          siteList: buildSitesWithPlumes(sortedSites, plumesList),
        },
      };
    }
    case `${FETCH_PLUMES}_FULFILLED`:
      return {
        ...state,
        site: {
          ...state.site,
          siteList: buildSitesWithPlumes(
            state.site.siteList,
            action.payload.plumes
          ),
        },
      };

    case `${FETCH_MODELS}_FULFILLED`: {
      const {
        model: { list: models },
      } = state;
      const sortedModels = sortByNames(models);

      return {
        ...state,
        model: {
          list: sortedModels,
        },
      };
    }

    case SELECT_SENSOR:
    case SELECT_ALL_SENSOR:
    case UNSELECT_SENSOR:
    case UNSELECT_ALL_SENSOR:
    case `${FETCH_ACTIVE_ALERTS}_FULFILLED`: {
      // Filter alerts
      const activeFilteredList = state.alert.activeList.filter(a => {
        const alertSType = state.sensor.typeBySensors[a.type];

        return state.sensor.selectedSensorTypes.includes(alertSType);
      });

      // Then group filtered alerts by device id
      const activeFilteredByDeviceId = activeFilteredList.reduce((obj, a) => {
        const cAlerts = obj[a.device];
        obj[a.device] = cAlerts ? [...cAlerts, a] : [a];
        return obj;
      }, {});

      return {
        ...state,
        alert: {
          ...state.alert,
          activeFilteredList,
          activeFilteredByDeviceId,
          activeFilteredByNodeId: enhanceWithAlerts(
            state.tree.nodesById,
            activeFilteredByDeviceId
          ),
        },
      };
    }
    default:
      return state;
  }
};

export default enhancementReducer;
