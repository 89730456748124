import React, { useCallback, useState, useEffect } from 'react';

import moment from 'moment';

import { Switch, Route, useHistory } from 'react-router';
import { connect } from 'react-redux';
import { setLocale } from 'react-redux-i18n';

import { ThemeProvider, CssBaseline } from '@material-ui/core';

import createTheme from '@common/createTheme';
import { logout } from '@common/redux/actions/auth';
import { fetchSites } from '@common/redux/actions/site';
import { fetchDevices } from '@common/redux/actions/device';
import { fetchAlarms } from '@common/redux/actions/alarm';
import { fetchActiveAlerts } from '@common/redux/actions/alert';
import { getUser, updateUser } from '@common/redux/actions/user';

import Loader from '@components/loader/Loader';
import Header from '@components/header/Header';
import Drawer from '@components/drawer/Drawer';
import AnalysisPage from '@pages/analysisPage/AnalysisPage';
import DevicesPage from '@pages/devicesPage/DevicesPage';
import GaugesPage from '@pages/gaugesPage/GaugesPage';

import useError from '@hooks/use-error';

import useStyles from '@styles';

const Main = ({
  authUser,
  tree,
  alerts,
  alertsByNodeId,
  getSites,
  getDevices,
  getAlarms,
  getAlerts,
  locale,
  acceptedLanguages,
  updateLocale,
  fetchUser,
  editUser,
  signOut,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { handleError } = useError();

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [themeType, setThemeType] = useState(
    localStorage.getItem('rubiX-theme') || 'dark'
  );

  const theme = createTheme(themeType);

  const handleSetLocale = useCallback(
    newLocale => {
      localStorage.setItem('rubiX-locale', newLocale);
      updateLocale(newLocale);
      editUser({ ...authUser, preferences: { lang: newLocale } });
    },
    [authUser, editUser, updateLocale]
  );

  useEffect(() => {
    if (!initialized) {
      fetchUser(authUser._id)
        .then(res => {
          localStorage.setItem('rubiX-id', res.value.user._id);

          // Set locale by user preferences if exist
          if (res.value.user.preferences && res.value.user.preferences.lang) {
            localStorage.setItem(
              'rubiX-locale',
              res.value.user.preferences.lang
            );
          } else {
            // Set default locale as user preferences if not exist
            const userLocale = localStorage.getItem('rubiX-locale');
            handleSetLocale(userLocale);
          }
        })
        .then(() => getSites())
        .then(() => getDevices())
        .then(() => getAlarms())
        .then(() =>
          getAlerts({
            from: moment().subtract(5, 'minutes').toISOString(),
            to: moment().toISOString(),
          })
        )
        .then(() => {
          setLoading(false);
        })
        .catch(err => {
          handleError(err);
          setLoading(false);
        });
      setInitialized(true);
    }
  }, [
    authUser._id,
    fetchUser,
    getAlarms,
    getAlerts,
    getDevices,
    getSites,
    handleError,
    handleSetLocale,
    initialized,
  ]);

  useEffect(() => {
    if (selectedDevice) {
      history.push('/gauges');
    } else {
      history.push('/');
    }
  }, [selectedDevice, history]);

  const handleSetDevices = useCallback(site => {
    if (site.children) {
      const hasDevices = site.children.some(child => child.isDevice);

      if (hasDevices) {
        setDevices(site.children.filter(child => child.isDevice));
        return;
      }
    }

    setDevices([]);
  }, []);

  useEffect(() => {
    if (tree) {
      setSites(tree);
      setSelectedSite(tree[0]);
      handleSetDevices(tree[0]);
    }
  }, [tree, handleSetDevices]);

  const handleSelectSite = useCallback(
    site => {
      setSelectedSite(site);
      handleSetDevices(site);
      setSelectedDevice(null);
      setOpenDrawer(false);
      history.push('/');
    },
    [history, handleSetDevices]
  );

  const handleUnSelectSite = useCallback(() => {
    setSelectedSite(null);
    setDevices([]);
    setOpenDrawer(true);
  }, []);

  return loading ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Loader />
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />

        <Header
          user={authUser}
          themeType={themeType}
          setThemeType={setThemeType}
          setOpenDrawer={setOpenDrawer}
          defaultLocale={locale}
          acceptedLanguages={acceptedLanguages}
          updateDefaultLocale={handleSetLocale}
          signOut={signOut}
          editUserSettings={settings => editUser({ ...authUser, ...settings })}
        />

        <Drawer
          open={openDrawer}
          setOpen={setOpenDrawer}
          sites={sites}
          selectedSite={selectedSite}
          onSiteSelected={handleSelectSite}
          alerts={alerts}
          alertsByNodeId={alertsByNodeId}
        />

        <div className={classes.toolbar} />

        <div className={classes.pageContent}>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <DevicesPage
                  site={selectedSite}
                  setDevices={handleSetDevices}
                  onUnSelectSite={handleUnSelectSite}
                  alertsByNodeId={alertsByNodeId}
                  devices={devices}
                  onSelectDevice={setSelectedDevice}
                />
              )}
            />
            <Route
              path="/gauges"
              render={() => (
                <GaugesPage
                  device={selectedDevice}
                  setDevice={setSelectedDevice}
                  themeType={themeType}
                />
              )}
            />
            <Route
              path="/analysis"
              render={() => (
                <AnalysisPage
                  devices={devices}
                  selectedDevice={selectedDevice}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  tree: state.tree.tree,
  alarms: state.alarm.list,
  alerts: state.alert.activeFilteredList,
  alertsByNodeId: state.alert.activeFilteredByNodeId,
  authUser: state.auth.user,
  locale: state.i18n.locale,
  acceptedLanguages: Object.keys(state.i18n.translations),
});

const mapDispatchToProps = dispatch => ({
  getSites: () => dispatch(fetchSites()),
  getDevices: () => dispatch(fetchDevices()),
  getAlarms: () => dispatch(fetchAlarms()),
  getAlerts: ({ from, to }) => dispatch(fetchActiveAlerts({ from, to })),
  fetchUser: userId => dispatch(getUser(userId)),
  editUser: data => dispatch(updateUser(data)),
  updateLocale: locale => dispatch(setLocale(locale)),
  signOut: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
