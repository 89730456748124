import axios from './axios';

const plumesService = {
  get: () => {
    const options = {
      method: 'GET',
      url: 'plumes',
    };

    return axios(options);
  },
};

export default plumesService;
