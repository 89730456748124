import {
  CREATE_DEVICE,
  UPDATE_DEVICE,
  UPDATE_DEVICE_CONFIGURATION_LOCK,
  GET_DEVICE,
  DELETE_DEVICE,
  FETCH_DEVICES,
} from '../actions/device';
import {
  CREATE_SITE,
  UPDATE_SITE,
  GET_SITE,
  DELETE_SITE,
  FETCH_SITES,
} from '../actions/site';
import { FETCH_PLUMES } from '../actions/plumes';

import { buildSitesWithPlumes } from '../../tools/siteTools';

const initialState = {
  siteList: [],
  deviceList: [],
  plumesList: [],
};

const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_SITE}_FULFILLED`:
      return {
        ...state,
        siteList: [...state.siteList, action.payload.site],
      };

    case `${UPDATE_SITE}_FULFILLED`:
    case `${GET_SITE}_FULFILLED`:
      return {
        ...state,
        siteList: state.siteList.map(site =>
          site._id === action.payload.site._id ? action.payload.site : site
        ),
      };

    case `${DELETE_SITE}_FULFILLED`:
      return {
        ...state,
        siteList: state.siteList.filter(
          site => site._id !== action.payload.site._id
        ),
      };

    case `${FETCH_SITES}_FULFILLED`:
      return {
        ...state,
        siteList: buildSitesWithPlumes(action.payload.sites, state.plumesList),
      };

    case `${CREATE_DEVICE}_FULFILLED`:
      return {
        ...state,
        deviceList: [...state.deviceList, action.payload.device],
      };

    case `${UPDATE_DEVICE}_FULFILLED`:
    case `${GET_DEVICE}_FULFILLED`:
    case `${UPDATE_DEVICE_CONFIGURATION_LOCK}_FULFILLED`:
      return {
        ...state,
        deviceList: state.deviceList.map(device =>
          device._id === action.payload.device._id
            ? action.payload.device
            : device
        ),
      };

    case `${DELETE_DEVICE}_FULFILLED`:
      return {
        ...state,
        deviceList: state.deviceList.filter(
          device => device._id !== action.payload.device._id
        ),
      };

    case `${FETCH_DEVICES}_FULFILLED`:
      return {
        ...state,
        deviceList: action.payload.devices,
      };

    case `${FETCH_PLUMES}_FULFILLED`:
      return {
        ...state,
        plumesList: action.payload.plumes,
      };

    default:
      return state;
  }
};

export default siteReducer;
