import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';
import numeral from 'numeral';
import clsx from 'clsx';

import { I18n, Translate } from 'react-redux-i18n';

import { levelAlertName } from '../../../tools/alarmTools';

import AnalysisIcon from '../../../images/menu/icon-stats.svg';

import useStyles from './styles';

const DASH_ARRAY = 60;
const LEVEL_OFFSETS = [0, 0, 50, 100, 100];
const ICON_STYLE = { width: '20px' };

const formatValue = value => `${numeral(value).format('0.[00]')}`;

const getUnitAndValue = (_unit, _value) => {
  let unit = _unit;
  let value = _value;
  if (unit === 'Ω') {
    if (`${Math.round(value)}`.length >= 5) {
      unit = `k${unit}`;
      value /= 1000;
    }
  }
  value = formatValue(value);
  return {
    unit,
    value,
  };
};

export const SensorItem = ({
  type,
  min,
  max,
  value,
  unit,
  alarm,
  alertLevel,
  deviceId,
  sensorId,
  siteId,
  tooltip,
}) => {
  const classes = useStyles();

  const range = useMemo(() => max - min, [max, min]);

  // Calculate value in percent
  const pct = useMemo(
    () => (value > max ? 1 : value < min ? 0 : (value - min) / range),
    [value, max, min, range]
  );

  const watched = useMemo(() => alertLevel > 0, [alertLevel]);
  const alertName = useMemo(() => levelAlertName(alertLevel), [alertLevel]);
  const containerClassName = useMemo(
    () => clsx(classes.item, watched && classes[alertName]),
    [classes, watched, alertName]
  );
  const containerTitle = useMemo(
    () => (tooltip ? I18n.t(`global.sensors.categories.${tooltip}`) : ''),
    [tooltip]
  );

  const fillGaugeClassName = useMemo(
    () => clsx(alarm ? classes.fillGaugeWithAlarm : classes.fillGaugeDefault),
    [alarm, classes]
  );

  const unitAndValue = useMemo(() => getUnitAndValue(unit, value), [
    unit,
    value,
  ]);
  const minUnitAndValue = useMemo(() => getUnitAndValue(unit, min), [
    unit,
    min,
  ]);
  const maxUnitAndValue = useMemo(() => getUnitAndValue(unit, max), [
    unit,
    max,
  ]);

  return (
    <div className={containerClassName} title={containerTitle}>
      <div className={classes.itemTop}>
        <Translate value={`global.sensors.types.${type}`} />
        <Link
          to={`/analysis?device=${deviceId}&sensor=${sensorId}&site=${siteId}`}
          className="resetLink"
        >
          <AnalysisIcon style={ICON_STYLE} />
        </Link>
      </div>

      <div className={classes.gauge}>
        <div className={classes.gaugeSvgWrapper}>
          <svg className={classes.gaugeSvg} viewBox="0 0 50 30">
            <defs>
              <linearGradient id="linearColors1">
                <stop
                  offset={`${LEVEL_OFFSETS[0]}%`}
                  className={classes.gaugeStopDefault}
                />
                <stop
                  offset={`${LEVEL_OFFSETS[1]}%`}
                  className={classes.gaugeStopDefault}
                />
                <stop
                  offset={`${LEVEL_OFFSETS[2]}%`}
                  className={classes.gaugeStopInfo}
                />
                <stop
                  offset={`${LEVEL_OFFSETS[3]}%`}
                  className={classes.gaugeStopCrit}
                />
                <stop
                  offset={`${LEVEL_OFFSETS[4]}%`}
                  className={classes.gaugeStopCrit}
                />
              </linearGradient>
            </defs>

            <path
              d="M5 25 A 20 20 0 0 1 45 25"
              fill="none"
              className={classes.emptyGauge}
              strokeWidth="3"
              strokeLinecap="round"
            />

            <path
              d="M5 25 A 20 20 0 0 1 45 25"
              fill="none"
              className={fillGaugeClassName}
              strokeWidth="3"
              strokeDasharray={`${DASH_ARRAY * pct}, 100`}
              strokeLinecap="round"
            />
          </svg>

          <div className={classes.value}>
            {unitAndValue.value}
            <span className={classes.unit}>{unitAndValue.unit}</span>
          </div>
        </div>

        <div className={classes.bottomValues}>
          <div>
            {minUnitAndValue.value}
            <span className={classes.bottomUnit}>{minUnitAndValue.unit}</span>
          </div>
          <div>
            {maxUnitAndValue.value}
            <span className={classes.bottomUnit}>{maxUnitAndValue.unit}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SensorList = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.list}>{children}</div>;
};

export default SensorList;
