import React, { useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SiteSelectionIcon from '@material-ui/icons/Menu';
import MenuIcon from '@material-ui/icons/MoreVert';

import darkLogo from '@common/images/logo-dark.png';
import lightLogo from '@common/images/logo-light.png';

import Settings from './settings/Settings';

import useStyles from './styles';

const Header = ({
  user,
  themeType,
  setThemeType,
  setOpenDrawer,
  defaultLocale,
  acceptedLanguages,
  updateDefaultLocale,
  signOut,
  editUserSettings,
}) => {
  const classes = useStyles();

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <AppBar color="default" position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setOpenDrawer(true)}
          >
            <SiteSelectionIcon />
          </IconButton>

          <div className={classes.logo}>
            <img
              src={themeType === 'dark' ? darkLogo : lightLogo}
              alt="logo"
              height={30}
            />
          </div>

          <IconButton
            color="inherit"
            aria-label="open menu"
            edge="end"
            size="small"
            onClick={() => setOpenMenu(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {openMenu && (
        <Settings
          open={openMenu}
          setOpen={setOpenMenu}
          user={user}
          themeType={themeType}
          setThemeType={setThemeType}
          defaultLocale={defaultLocale}
          acceptedLanguages={acceptedLanguages}
          updateDefaultLocale={updateDefaultLocale}
          signOut={signOut}
          editUserSettings={editUserSettings}
        />
      )}
    </>
  );
};

export default Header;
