import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loginPage: {
    backgroundColor: theme.palette.secondary.backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  rubixLogoImage: {
    maxWidth: '300px',
    width: '80%',
    marginBottom: '10vh',
  },
  alternative: {
    textAlign: 'center',
    position: 'fixed',
    bottom: theme.spacing(1),
    color: theme.palette.secondary.color,
  },
  [theme.breakpoints.down('sm')]: {
    rubixLogoImage: {
      width: '60%',
      marginBottom: '5vh',
    },
  },
}));

export default useStyles;
