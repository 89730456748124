import React from 'react';

import { I18n, Translate } from 'react-redux-i18n';

import Hidden from '@material-ui/core/Hidden';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';

import IconTitle from '@common/components/iconTitle/IconTitle';
import AlertBox from '@common/components/alertBox/AlertBox';

import { maxLevelAlert } from '@common/tools/alarmTools';
import SiteIcon from '@common/images/icons/icon-usine.svg';

import SiteTreeWithAlerts from '@components/siteTreeWithAlert/SiteTreeWithAlert';

import useStyles from './styles';

const Drawer = ({
  open,
  setOpen,
  sites,
  selectedSite,
  onSiteSelected,
  alerts,
  alertsByNodeId,
}) => {
  const classes = useStyles();

  return (
    <Hidden smUp implementation="css">
      <SwipeableDrawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ className: classes.drawer }}
      >
        <div className={classes.header}>
          <IconTitle
            wrapperClass={classes.iconWrapper}
            title={I18n.t('page.navigator.menu.sites.title')}
            icon={<SiteIcon />}
            withBackground
            titleItem
          />
          {alerts.length > 0 && (
            <AlertBox count={alerts.length} level={maxLevelAlert(alerts)} />
          )}
        </div>

        <Divider className={classes.divider} />

        {(sites.length === 0 || !sites) && (
          <div className={classes.noSite}>
            <Translate value="page.navigator.sites.noSites" />
          </div>
        )}

        <SiteTreeWithAlerts
          style={{ height: '300px' }}
          sites={sites}
          treeLocked
          expandLevel={1}
          onSiteSelected={onSiteSelected}
          selectedSite={selectedSite}
          alertsByNodeId={alertsByNodeId}
        />
      </SwipeableDrawer>
    </Hidden>
  );
};

export default Drawer;
