import React from 'react';

import { Translate } from 'react-redux-i18n';

import ComputerIcon from '@material-ui/icons/Computer';
import MobileIcon from '@material-ui/icons/Smartphone';

import useStyles from './styles';

const Icon = ({ alternative }) => {
  if (alternative === 'web') {
    return <ComputerIcon fontSize="small" />;
  }

  return <MobileIcon fontSize="small" />;
};

const AlternativeVersion = ({ alternative = 'mobile' }) => {
  const classes = useStyles();
  let link = window.RUBIX_SETTINGS.mobileUrl;

  if (alternative === 'web') {
    link = window.RUBIX_SETTINGS.webUrl;
  }

  return (
    <a href={link} rel="alternate" target="_self" className={classes.link}>
      <Icon alternative={alternative} />{' '}
      <Translate value={`global.versions.goTo${alternative}`} />
    </a>
  );
};

export default AlternativeVersion;
