import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    boxShadow: `2px 2px 5px 1px ${theme.palette.background.paper}`,
    borderRadius: '5px',
    width: '22%',
    height: '15vh',
    margin: '1vh 0',
    marginLeft: '1%',
    padding: theme.spacing(1),
    maxHeight: 120,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    boxShadow: `0px 1px 5px 1px ${theme.palette.alert.info}`,
  },
  warn: {
    boxShadow: `0px 1px 5px 1px ${theme.palette.alert.warn}`,
  },
  crit: {
    boxShadow: `0px 1px 5px 1px ${theme.palette.alert.crit}`,
  },
  icon: {
    width: 70,
    height: 'auto',
    marginRight: 10,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontWeight: 'bold',
  },
  ref: {
    fontStyle: 'italic',
  },
  alerts: {
    background: theme.palette.secondary.dark,
    padding: '3px 5px',
    borderRadius: '10px',
    fontSize: '0.65rem',
    marginTop: 5,
  },
  [theme.breakpoints.down('sm')]: {
    item: {
      width: '75%',
      minHeight: 80,
    },
    icon: {
      width: '100%',
      maxWidth: 40,
    },
  },
}));

export default useStyles;
