import { useEffect, useRef } from 'react';

const useInterval = ({ callback, delay, callAtInit }) => {
  const savedCallback = useRef();
  const savedCallAtInit = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    savedCallAtInit.current = callAtInit;
  }, [callAtInit]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (typeof delay === 'number') {
      if (savedCallAtInit.current) {
        tick();
      }
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
    return () => {};
  }, [delay]);
};

export default useInterval;
