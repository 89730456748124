import moment from 'moment';
import momentIterator from 'moment-iterator';

export const getStep = range => {
  if (range > 200000) {
    return 100000;
  }
  if (range > 20000) {
    return 10000;
  }
  if (range > 2000) {
    return 1000;
  }
  if (range > 200) {
    return 100;
  }
  if (range > 100) {
    return 10;
  }
  if (range > 10) {
    return 5;
  }
  if (range > 1) {
    return 1;
  }
  return 0.1;
};

export const formatDateTick = (tick, value) => {
  if (!tick) {
    return moment(value).format('DD MMM. YYYY - HH:mm:ss');
  }
  if (tick === 'minutes' || tick === 'hours') {
    return moment(value).format('HH:mm:ss');
  }
  if (tick === 'days') {
    return moment(value).format('MMM. DD');
  }
  return moment(value).format('MMM. YYYY');
};

export const buildTickValues = (min, max) => {
  // Build tickValues based on min and max values
  const tickValues = [];
  const newMin = min < 0 ? 0 : min;
  const pad = getStep(max - newMin);

  for (let index = min; index <= max; ) {
    index = Math.round(index * 100) / 100; // Round to avoid max overflow
    tickValues.push(index);

    index += pad;
  }

  return tickValues;
};

export const buildTick = (startDate, endDate) => {
  let tick = 'months';
  const periodInDays = endDate.diff(startDate, 'days'); // Diff in days

  if (periodInDays <= 1) {
    const periodInMillis = endDate.diff(startDate); // Diff in milliseconds

    if (periodInMillis <= 1000 * 120 * 60) {
      // Lower than two hours
      tick = 'minutes';
    } else {
      tick = 'hours';
    }
  } else if (periodInDays <= 31) {
    tick = 'days';
  }

  return tick;
};

export const buildTimes = (startDate, endDate, tick) => {
  // Calculate X axis tick interval and values
  const times = [];

  momentIterator(startDate.toDate(), endDate.toDate()).each(
    tick === 'minutes' ? '5 minutes' : tick,
    dayDate => {
      times.push(dayDate.unix() * 1000);
    }
  );

  return times;
};

export const buildTimesForMobile = (startDate, endDate, tick) => {
  // Calculate X axis tick interval and values
  const times = [];
  let coef = 5;

  if (tick === 'hours') {
    coef = 4;
  }
  if (tick === 'days') {
    if (endDate.diff(startDate, 'days') < 10) {
      coef = 1;
    } else if (endDate.diff(startDate, 'days') < 20) {
      coef = 3;
    } else {
      coef = 6;
    }
  }
  if (tick === 'months') {
    coef = 1;
  }

  momentIterator(startDate.toDate(), endDate.toDate()).each(
    `${coef} ${tick}`,
    dayDate => {
      times.push(dayDate.unix() * 1000);
    },
    { trailing: true }
  );

  return times;
};

export const getInterval = (startDate, endDate) => {
  let tick = '1 day';
  const periodInDays = endDate.diff(startDate, 'hours'); // Diff in hours

  if (periodInDays <= 24) {
    const periodInMillis = endDate.diff(startDate); // Diff in milliseconds

    if (periodInMillis <= 1000 * 600 * 60) {
      // Lower than 10 hours
      tick = '5 minutes';
    } else if (periodInMillis <= 1000 * 1200 * 60) {
      // Lower than 20 hours
      tick = '30 minutes';
    } else if (periodInMillis <= 1000 * 1440 * 60) {
      // Lower than 24 hours
      tick = '1 hour';
    }
  }

  return tick;
};

export const getNbAxis = (sensorsList, hasBarSeries) => {
  if (!sensorsList) {
    return 1;
  }
  // YAxis contexts
  const yAxisCtxs = {};

  // Loop over sensors
  sensorsList.forEach(sensor => {
    let axisCtx = yAxisCtxs[sensor.sensorType];

    if (!axisCtx) {
      // eslint-disable-next-line
      axisCtx = yAxisCtxs[sensor.sensorType] = {
        [sensor.unit]: true,
      };
    } else {
      axisCtx[sensor.unit] = true;
    }
  });

  const axisCategoryKeys = Object.keys(yAxisCtxs);
  const axisKeys = [];
  axisCategoryKeys.forEach(category => {
    Object.keys(yAxisCtxs[category]).forEach(unit => {
      axisKeys.push(yAxisCtxs[category][unit]);
    });
  });

  return axisKeys.length + (hasBarSeries ? 1 : 0);
};
