import React, { useState, useEffect } from 'react';

import { useXTick } from '@hooks/use-chart';

import BarChart, { Legend } from './barChart/BarChart';
import LineChart from './lineChart/LineChart';

import 'react-vis/dist/style.css';

const Chart = ({ start, end, data, sensor, alarms, odorCategories }) => {
  const { tick, times } = useXTick(start, end);
  const xDomain = [start.unix() * 1000, end.unix() * 1000];

  // needed to correctly resize on orientation change in iOS
  const [key, setKey] = useState(0);

  useEffect(() => {
    const rerender = () => setKey(k => k + 1);

    window.addEventListener('orientationchange', rerender);

    return () => window.removeEventListener('orientationchange', rerender);
  }, []);

  if (sensor.ts === 'identification') {
    return (
      <React.Fragment key={key}>
        <BarChart
          start={start.unix() * 1000}
          end={end.unix() * 1000}
          tick={tick}
          times={times}
          data={data}
          sensor={sensor}
          xDomain={xDomain}
        />
        <Legend legend={odorCategories} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment key={key}>
      <LineChart
        tick={tick}
        times={times}
        data={data}
        sensor={sensor}
        xDomain={xDomain}
        alarms={alarms}
        start={start.unix() * 1000}
        end={end.unix() * 1000}
      />
    </React.Fragment>
  );
};

export default Chart;
