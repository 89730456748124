import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '90vw',
    padding: theme.spacing(1),
    paddingTop: theme.mixins.toolbar.minHeight,
    backgroundColor: theme.palette.background.paper,
    '& svg': {
      fill: theme.palette.text.primary,
    },
    '& button': {
      backgroundColor: theme.palette.text.primary,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconWrapper: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(1),
    '& svg': {
      fill:
        theme.palette.type === 'light'
          ? theme.palette.secondary.contrastText
          : theme.palette.secondary.dark,
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  noSite: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
