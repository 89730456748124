import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'fixed',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: `calc(100vh - ${theme.mixins.toolbar})`,
  },
  toolbar: theme.mixins.toolbar,
  pageContent: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    maxHeight: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    overflow: 'auto',
  },
}));

export default useStyles;
