import {
  CREATE_ALARM,
  UPDATE_ALARM,
  DELETE_ALARM,
  FETCH_ALARMS,
} from '../actions/alarm';
import {
  CREATE_TIME_PERIOD,
  UPDATE_TIME_PERIOD,
  DELETE_TIME_PERIOD,
  FETCH_TIME_PERIODS,
} from '../actions/timePeriod';

const initialState = {
  list: [],
  timePeriods: [],
};

const alarmReducer = (state = initialState, action) => {
  switch (action.type) {
    // Alarms
    case `${CREATE_ALARM}_FULFILLED`:
      return {
        ...state,
        list: [...state.list, action.payload.alarm],
      };

    case `${UPDATE_ALARM}_FULFILLED`:
      return {
        ...state,
        list: state.list.map(alarm =>
          alarm._id === action.payload.alarm._id ? action.payload.alarm : alarm
        ),
      };

    case `${DELETE_ALARM}_FULFILLED`:
      return {
        ...state,
        list: state.list.filter(
          alarm => alarm._id !== action.payload.alarm._id
        ),
      };

    case `${FETCH_ALARMS}_FULFILLED`:
      return {
        ...state,
        list: action.payload.alarms,
      };

    // Time Period
    case `${CREATE_TIME_PERIOD}_FULFILLED`:
      return {
        ...state,
        timePeriods: [...state.timePeriods, action.payload.timeperiod],
      };

    case `${UPDATE_TIME_PERIOD}_FULFILLED`:
      return {
        ...state,
        timePeriods: state.timePeriods.map(timePeriod =>
          timePeriod._id === action.payload.timeperiod._id
            ? action.payload.timeperiod
            : timePeriod
        ),
      };

    case `${DELETE_TIME_PERIOD}_FULFILLED`:
      return {
        ...state,
        timePeriods: state.timePeriods.filter(
          timePeriod => timePeriod._id !== action.payload.timeperiod._id
        ),
      };

    case `${FETCH_TIME_PERIODS}_FULFILLED`:
      return {
        ...state,
        timePeriods: action.payload.timeperiods,
      };

    default:
      return state;
  }
};

export default alarmReducer;
