import moment from 'moment';
import { I18n } from 'react-redux-i18n';

const startDate = moment().set({ hour: 0, minute: 0, second: 0 });
const endDate = moment().set({ hour: 23, minute: 59, second: 59 });

const yesterday = [
  moment(startDate).subtract(1, 'days'),
  moment(endDate).subtract(1, 'days'),
];

const getDateRanges = (opts = {}) => {
  const dateRanges = {};

  if (opts.hasOwnProperty('realtime')) {
    dateRanges[I18n.t('global.form.inputs.datePicker.realtime')] = [
      moment().subtract(60, 'minutes'),
      moment(),
    ];
  }

  dateRanges[I18n.t('global.form.inputs.datePicker.today')] = [
    moment(startDate),
    moment(endDate),
  ];
  dateRanges[I18n.t('global.form.inputs.datePicker.yesterday')] = yesterday;
  dateRanges[I18n.t('global.form.inputs.datePicker.last7Days')] = [
    moment(startDate).subtract(7, 'days'),
    moment(startDate),
  ];
  dateRanges[I18n.t('global.form.inputs.datePicker.last30Days')] = [
    moment(startDate).subtract(30, 'days'),
    moment(startDate),
  ];
  dateRanges[I18n.t('global.form.inputs.datePicker.thisMonth')] = [
    moment(startDate).startOf('month'),
    moment(startDate).endOf('month'),
  ];
  dateRanges[I18n.t('global.form.inputs.datePicker.lastMonth')] = [
    moment(startDate).subtract(1, 'month').startOf('month'),
    moment(startDate).subtract(1, 'month').endOf('month'),
  ];

  return dateRanges;
};

export default {
  startDate,
  endDate,
  yesterday,
  getDateRanges,
};
