import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  rootPanel: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.background.main,
    '&::before': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      fill: theme.palette.text.primary,
    },
  },
  roundedPanel: {
    '&:last-child': {
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      margin: 0,
    },
  },
  rootAction: {
    justifyContent: 'center',
  },
  heading: {
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  iconWrapper: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(1),
    '& svg': {
      fill:
        theme.palette.type === 'light'
          ? theme.palette.secondary.contrastText
          : theme.palette.secondary.dark,
    },
  },
  panelDetail: {
    maxHeight: 200,
    overflow: 'auto',
  },
}));

export default useStyles;
