import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    width: '97%',
    margin: '0 1%',
    marginBottom: '2vh',
    padding: '1vh 2%',
    paddingBottom: 0,
    maxWidth: 490,
  },
  itemTop: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& svg': {
      width: 30,
      height: 30,
      fill: theme.palette.text.primary,
    },
  },
  warn: {
    border: `1px solid ${theme.palette.alert.warn}`,
  },
  info: {
    border: `1px solid ${theme.palette.alert.info}`,
  },
  crit: {
    border: `1px solid ${theme.palette.alert.crit}`,
  },
  content: {
    display: 'flex',
  },
  gaugeContainer: {
    display: 'flex',
    height: 190,
    width: 280,
    alignSelf: 'center',
    margin: 'auto',
    '& svg': {
      height: 'auto',
      width: 'auto',
    },
  },
  legendList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    margin: '1vh 0',
    flexWrap: 'nowrap',
    maxWidth: '40%',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.background.default,
    padding: '3px 5px',
    borderRadius: 5,
  },
  legendPinItem: {
    width: 10,
    height: 10,
    display: 'inline-block',
    borderRadius: '50%',
    margin: theme.spacing(0.5),
  },
  legendItemWithoutPin: {
    flex: 1,
    textAlign: 'center',
    '& span': {
      fontSize: '115%',
      padding: '5px 10px',
      borderRadius: '5px',
      backgroundColor: theme.palette.background.default,
    },
  },
  [theme.breakpoints.up('sm')]: {
    item: {
      maxWidth: 490,
    },
    content: {
      flexDirection: 'row',
      width: '100%',
    },
    legendList: {
      maxWidth: '100%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    itemTop: {
      '& svg': {
        width: 15,
        height: 15,
      },
      '& a': {
        alignSelf: 'flex-start',
      },
    },
    item: {
      maxWidth: 150,
      boxSizing: 'border-box',
    },
    content: {
      flexDirection: 'column',
      width: '100%',
    },
    gaugeContainer: {
      height: 90,
      width: 150,
    },
    legendList: {
      maxWidth: '100%',
    },
    legendItem: {
      fontSize: '0.7rem',
    },
    legendPinItem: {
      width: 5,
      height: 5,
    },
  },
}));

export default useStyles;
