import React from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { I18n } from 'react-redux-i18n';
import clsx from 'clsx';

import { ArcSeries, FlexibleXYPlot } from 'react-vis';
import { levelIsInAlert } from '../../../tools/alarmTools';

import {
  formatChartData,
  formatLegend,
} from '../../../tools/identificationTools';

import AnalysisIcon from '../../../images/menu/icon-stats.svg';

import useStyles from './styles';

const OdorSensor = ({
  value,
  categories,
  alarm,
  alertLevel,
  deviceId,
  siteId,
  tooltip,
  // needed to fill empty arc serie with corresponding theme color,
  themeType,
}) => {
  const classes = useStyles();
  const watched = !!alarm;

  const alarmName = levelIsInAlert(alertLevel); // Set as alert if is the case

  const data = formatChartData(value, categories, themeType);
  const legend = formatLegend(value, categories);

  return (
    <div
      className={clsx(classes.item, watched && classes[alarmName])}
      title={tooltip ? I18n.t(`global.sensors.categories.${tooltip}`) : ''}
    >
      <div className={classes.itemTop}>
        <Link
          to={`/analysis?device=${deviceId}&sensor=identification&site=${siteId}`}
          className="resetLink"
        >
          <AnalysisIcon style={{ width: '20px' }} />
        </Link>
      </div>

      <div className={classes.content}>
        <div className={classes.gaugeContainer}>
          <div style={{ width: '100%' }}>
            <FlexibleXYPlot
              xDomain={[0, 10]}
              yDomain={[0, 10]}
              margin={{ left: 0, top: 10, bottom: 0 }}
            >
              <ArcSeries
                animation
                data={data}
                radiusDomain={[0, 3]}
                colorType="literal"
                center={{ x: 4.5, y: 3 }}
              />
            </FlexibleXYPlot>
          </div>
        </div>

        <div className={classes.legendList}>
          {legend.map((odor, idx) => (
            <div className={classes.legendItem} key={`${odor.color}-${idx}`}>
              <div
                className={classes.legendPinItem}
                style={{ backgroundColor: odor.color }}
              />
              {`${odor.name} : ${numeral(odor.value * 100).format('0.[00]')}%`}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OdorSensor;
