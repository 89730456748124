import React from 'react';

import { Translate } from 'react-redux-i18n';

import DevicesList from '@common/components/devices/deviceList/DeviceList';

import SiteNavigation from '@components/siteNavigation/SiteNavigation';

import useStyles from './styles';

const DevicesPage = ({
  site,
  devices,
  setDevices,
  onUnSelectSite,
  alertsByNodeId,
  onSelectDevice,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {devices.length > 0 ? (
        <>
          <div className={classes.listContainer}>
            <DevicesList
              devices={devices}
              alertByNodeId={alertsByNodeId}
              onDeviceClicked={onSelectDevice}
            />
          </div>
          <div className={classes.separator} />
        </>
      ) : (
        <div className={classes.noDevice}>
          <Translate value="page.navigator.sites.noDevices" />
        </div>
      )}

      {site && (
        <SiteNavigation
          site={site}
          devices={devices}
          setDevices={setDevices}
          onUnSelectSite={onUnSelectSite}
          alertsByNodeId={alertsByNodeId}
        />
      )}
    </div>
  );
};

export default DevicesPage;
