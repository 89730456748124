import languages from 'Lang'; // eslint-disable-line import/no-unresolved

// i18n
import englishTranslation from '../i18n/en.json';
import frenchTranslation from '../i18n/fr.json';
import chineseTranslation from '../i18n/cn.json';
import japaneseTranslation from '../i18n/jp.json';
import germanTranslation from '../i18n/de.json';

export const translationsObject = {
  en: englishTranslation,
  fr: frenchTranslation,
  zh: chineseTranslation,
  ja: japaneseTranslation,
  de: germanTranslation,
};

export const getLocale = () => {
  let locale = localStorage.getItem('rubiX-locale');

  if (locale && Object.keys(translationsObject).includes(locale)) {
    return locale;
  }

  const locales = navigator.languages;

  for (let i = 0; i < locales.length; i++) {
    if (Object.keys(translationsObject).includes(locales[i].substr(0, 2))) {
      locale = locales[i].substr(0, 2);
      return locale;
    }
  }

  return window.RUBIX_SETTINGS.defaultLanguage;
};

export const retriveLang = language => {
  let lang = 'Not found';
  languages.forEach(l => {
    if (l.locale === language) {
      ({ lang } = l);
    } // eslint-disable-line prefer-destructuring
  });

  return lang;
};

export const retriveFlag = language => {
  let flag = 'us';

  languages.forEach(l => {
    if (l.locale === language) {
      ({ flag } = l);
    } // eslint-disable-line prefer-destructuring
  });
  return flag;
};
