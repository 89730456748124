import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  lines: {
    '& line': {
      stroke: theme.palette.divider,
      opacity: 0.5,
    },
  },
}));

export default useStyles;
