import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  alertBox: {
    padding: theme.spacing(0, 0.5),
    borderRadius: '20px',
    width: 35,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: theme.palette.white,
    textShadow: '0 0 2px #262626',
  },
  warn: {
    backgroundColor: theme.palette.alert.warn,
  },
  info: {
    backgroundColor: theme.palette.alert.info,
  },
  crit: {
    backgroundColor: theme.palette.alert.crit,
  },
}));

export default useStyles;
