import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';

import { fetchLast } from '../../redux/actions/analysis';
import { fetchLastIdentification } from '../../redux/actions/identification';
import { fetchActiveAlerts } from '../../redux/actions/alert';

import {
  formatIdentificationDatas,
  setOdorCategoriesColors,
} from '../../tools/identificationTools';
import OdorSensor from '../sensors/odorSensor/OdorSensor';

import DashboardCategory from './DashboardCategory';

import './Dashboard.scss';
import useInterval from '../../hooks/use-interval';
import useTabIsHidden from '../../hooks/use-tab-is-hidden';

const LOAD_DATA_DELAY = 15000;

const Dashboard = ({
  deviceId,
  // render a different component, allowing adding an action (for mobile use)
  navigationHeader,
  // needed to fill empty arc serie with corresponding theme color,
  themeType,
}) => {
  const dispatch = useDispatch();

  const alarmsList = useSelector(state => state.alarm.list);
  const device = useSelector(state =>
    state.site.deviceList.find(d => d._id === deviceId)
  );

  const [initialized, setInitialized] = useState(false);
  const [valuesBySensorsType, setValuesBySensorsType] = useState({});
  const [odorCategories, setOdorCategories] = useState([]);
  const fullSensorsList = device.sensors_list.concat(device.combine_list);
  const modelId = device.model_id;
  const alarms = alarmsList.filter(alarm => alarm.model_id === modelId);
  const tabIsHidden = useTabIsHidden();

  useEffect(() => {
    if (!initialized) {
      const nowDate = moment();
      const startDate = moment(nowDate).subtract(5, 'minutes'); // Substract one hour

      dispatch(
        fetchActiveAlerts({
          from: startDate.toISOString(),
          to: nowDate.toISOString(),
        })
      );

      setInitialized(true);
    }
  }, [dispatch, initialized]);

  const loadData = useCallback(() => {
    const from = moment().subtract(10, 'minutes').toISOString();
    const to = moment().toISOString();
    const sources = [device._id];
    const types = fullSensorsList.map(sensor => sensor.name);

    dispatch(
      fetchLast({
        from,
        to,
        sources,
        types,
        interval: '1 minute',
      })
    )
      .then(({ value }) => {
        let updatedValuesBySensorType = {};

        if (value.data) {
          updatedValuesBySensorType = value.data.reduce(
            (_valuesBySensorType, values) => {
              Object.keys(values)
                .filter(key => key !== 'date')
                .forEach(key => {
                  if (values[key] && !_valuesBySensorType[key]) {
                    _valuesBySensorType[key] = values[key];
                  }
                });

              return _valuesBySensorType;
            },
            {}
          );
        }

        setValuesBySensorsType({
          ...valuesBySensorsType,
          ...updatedValuesBySensorType,
        });

        return { ...valuesBySensorsType, ...updatedValuesBySensorType };
      })
      .then(values => {
        // set identification if exist in device
        if (device.identification) {
          dispatch(fetchLastIdentification({ from, to, sources })).then(
            ({ value }) => {
              setOdorCategories(setOdorCategoriesColors(value.labels));

              if (value && value.data.length > 0) {
                const sensorKey = Object.keys(value.data[0])[0];

                values[sensorKey] = formatIdentificationDatas(
                  value.data[0][sensorKey],
                  themeType
                );

                setValuesBySensorsType({ ...valuesBySensorsType, ...values });
              }
            }
          );
        }
      });
  }, [
    device._id,
    device.identification,
    dispatch,
    fullSensorsList,
    themeType,
    valuesBySensorsType,
  ]);

  const loadDataDelay = useMemo(() => !tabIsHidden && LOAD_DATA_DELAY, [
    tabIsHidden,
  ]);

  useInterval({
    callback: loadData,
    delay: loadDataDelay,
    callAtInit: true,
  });

  return (
    <div className="page">
      <div className="dashboardContent">
        {navigationHeader ? (
          navigationHeader()
        ) : (
          <div className="dashboardDevice">{device.name}</div>
        )}

        <div className="dashboardList">
          {device.sensorChildren.map(category => (
            <DashboardCategory
              key={`${category.name}`}
              category={category}
              valuesBySensorsType={valuesBySensorsType}
              device={device}
              alarms={alarms}
            />
          ))}

          {device.identification && (
            <OdorSensor
              deviceId={device._id}
              siteId={device.site_id}
              value={valuesBySensorsType[`${device._id}-identification`]}
              categories={odorCategories}
              tooltip="identification"
              themeType={themeType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
