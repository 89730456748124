import axios from './axios';

const deviceServices = {
  create: device => {
    const options = {
      method: 'POST',
      url: 'devices',
      data: device,
    };

    return axios(options);
  },

  update: (deviceId, device) => {
    const options = {
      method: 'PATCH',
      url: `devices/${deviceId}`,
      data: device,
    };

    return axios(options);
  },

  updateConfigurationLock: (deviceId, locked) => {
    const options = {
      method: 'PATCH',
      url: `devices/${deviceId}`,
      data: { locked },
    };

    return axios(options);
  },

  delete: deviceId => {
    const options = {
      method: 'PATCH',
      url: `devices/${deviceId}/delete`,
    };

    return axios(options);
  },

  fetchDevices: () => {
    const options = {
      method: 'GET',
      url: 'devices',
    };

    return axios(options);
  },

  getDevice: deviceId => {
    const options = {
      method: 'GET',
      url: `devices/${deviceId}`,
    };

    return axios(options);
  },

  getOnOff: deviceId => {
    const options = {
      method: 'GET',
      url: `onoff/${deviceId}`,
    };

    return axios(options);
  },

  toggleOnOff: (deviceId, value) => {
    const options = {
      method: 'POST',
      url: `onoff/${deviceId}`,
      data: { trigger: value },
    };

    return axios(options);
  },
};

export default deviceServices;
