import {
  CREATE_CALCULUS,
  UPDATE_CALCULUS,
  FETCH_CALCULUS,
  FETCH_CALCULUS_BY_ID,
  DELETE_CALCULUS,
} from '../actions/calculus';

const getUpdatedList = (prevList, calculus) => {
  let newList = prevList;
  if (calculus) {
    const currentCalculusIdx = prevList.findIndex(c => c._id === calculus._id);
    if (currentCalculusIdx === -1) {
      newList = [...newList, calculus];
    } else {
      newList[currentCalculusIdx] = {
        ...newList[currentCalculusIdx],
        ...calculus,
      };
    }
  }
  return newList;
};

const initialState = {
  list: [],
  calculusId: null,
};

const calculusReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_CALCULUS}_FULFILLED`:
      return {
        ...state,
        calculusId: action.payload.calculus._id,
      };

    case `${UPDATE_CALCULUS}_FULFILLED`:
      return {
        ...state,
        list: getUpdatedList(state.list, action.payload.calculus),
        calculusId: null,
      };

    case `${FETCH_CALCULUS}_FULFILLED`:
      return {
        ...state,
        list: action.payload.calculus,
      };

    case `${FETCH_CALCULUS_BY_ID}_FULFILLED`:
      return {
        ...state,
        list: getUpdatedList(state.list, action.payload.calculus),
      };

    case `${DELETE_CALCULUS}_FULFILLED`:
      return {
        ...state,
        list: state.list.filter(
          calculus => calculus._id !== action.payload.calculus._id
        ),
      };

    default:
      return state;
  }
};

export default calculusReducer;
