import React, { useState } from 'react';

import { Translate, I18n } from 'react-redux-i18n';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import useStyles from './styles';

const ForgottenPasswordForm = ({
  setForgottenPassword,
  handleForgottenPassword,
  error,
}) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');

  const handleSubmit = event => {
    setForgottenPassword(true);
    handleForgottenPassword(event, email);
  };

  return (
    <form className={classes.forgottenPasswordForm} onSubmit={handleSubmit}>
      <div>
        <TextField
          fullWidth
          name="email"
          type="email"
          margin="normal"
          variant="outlined"
          label={I18n.t('login.form.label.email')}
          required
          value={email}
          onChange={event => setEmail(event.target.value)}
          inputProps={{ className: classes.responsiveInput }}
          InputLabelProps={{ className: classes.responsiveLabel }}
        />

        {error && (
          <div className={classes.sendForgottenPasswordError}>
            <Translate value={error} />
          </div>
        )}
        <Button type="submit" className={classes.sendEmailButton}>
          <Translate value="login.sendEmail" />
        </Button>
      </div>
    </form>
  );
};

export default ForgottenPasswordForm;
