import modelServices from '../../services/model';

export const CLONE_MODEL = 'CLONE_MODEL';
export const CREATE_MODEL = 'CREATE_MODEL';
export const UPDATE_MODEL = 'UPDATE_MODEL';
export const FETCH_MODELS = 'FETCH_MODELS';
export const DELETE_MODEL = 'DELETE_MODEL';

export function cloneModel(model) {
  return {
    type: CLONE_MODEL,
    payload: modelServices.clone(model),
  };
}

export function createModel(model) {
  return {
    type: CREATE_MODEL,
    payload: modelServices.create(model),
  };
}

export function updateModel(model) {
  // Remove id if needed
  const modelId = model._id;
  delete model._id;

  return {
    type: UPDATE_MODEL,
    payload: modelServices.update(modelId, model),
  };
}

export function fetchModels() {
  return {
    type: FETCH_MODELS,
    payload: modelServices.fetchModels(),
  };
}

export function deleteModel(modelId) {
  return {
    type: DELETE_MODEL,
    payload: modelServices.delete(modelId),
  };
}
