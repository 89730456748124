export const buildSiteTreeContext = (sites, devices, typeBySensors) => {
  //
  // Prepare array
  //
  const enhancedDevices =
    devices &&
    devices.map(device => {
      // Group sensors by sensor types
      const sensorsByType = {};

      if (device.sensors_list) {
        device.sensors_list.forEach(sensor => {
          const type = typeBySensors[sensor.ts] || 'other';

          // Create or get sensor type
          const sensorType = sensorsByType[type]
            ? sensorsByType[type]
            : {
                _id: `${device._id}_sensor_${type}`,
                name: type,
                isSensorType: true,
                children: [],
              };

          // Add sensor to sensor type
          sensorType.children.push({
            ...sensor,
            isSensor: true,
          });

          sensorsByType[type] = sensorType;
        });
      }

      if (device.combine_list) {
        device.combine_list.forEach(virtualSensor => {
          const type = typeBySensors[virtualSensor.ts] || 'other';

          // Create or get sensor type
          const sensorType = sensorsByType[type]
            ? sensorsByType[type]
            : {
                _id: `${device._id}_combine_sensor_${type}`,
                name: type,
                isSensorType: true,
                children: [],
              };

          // Add sensor to sensor type
          sensorType.children.push({
            ...virtualSensor,
            isSensor: true,
            isVirtual: true,
          });

          sensorsByType[type] = sensorType;
        });
      }

      if (device.identification) {
        const type = 'identification';

        const sensorType = {
          _id: `${device._id}_${type}`,
          name: type,
          ts: type,
          isSensorType: true,
          isVirtual: true,
          children: [],
        };

        sensorType.children.push({
          ...device.identification,
          name: type,
          ts: type,
          isSensor: true,
          isVirtual: true,
          _id: `${device._id}_${type}`,
        });

        sensorsByType[type] = sensorType;
      }

      // Create list of sensor types
      // const childrenList =
      // childrenList;
      const sensorChildren = Object.keys(sensorsByType).map(
        key => sensorsByType[key]
      );
      device.sensorChildren = sensorChildren;
      // Override device object with enhanced data
      return {
        ...device,
        // TODO: Check why we have to call it sensorChildren instead of children. children prop is not saved ?!
        sensorChildren,
        isDevice: true,
        parent_site: device.site_id,
        tech_specs: device.tech_specs
          ? device.tech_specs
          : { kind: 'WT1', soft_version: '1.0' }, // TODO Remove this ASAP
      };
    });

  // Clone sites
  const copySites = sites ? sites.map(site => ({ ...site })) : [];

  const fullArray = copySites.concat(enhancedDevices || []);

  //
  // Keep track of elements by ids
  //
  const nodesById = fullArray.reduce((_nodesById, node) => {
    _nodesById[node._id] = node;

    // Re init children
    if (node.children) {
      delete node.children;
    }

    return _nodesById;
  }, {});

  //
  // Build tree
  //

  const tree = [];

  Object.keys(nodesById).forEach(nodeId => {
    const node = nodesById[nodeId];

    if (node.parent_site && nodesById[node.parent_site]) {
      const parentNode = nodesById[node.parent_site];

      if (parentNode) {
        parentNode.children = parentNode.children ? parentNode.children : []; // Init children array if needed
        parentNode.children.push(node);
      }
    } else {
      tree.push(node);
    }
  });

  // console.log("const defautSitelist = " + JSON.stringify(sites) + ";");
  // console.log("const defautDevicelist = " + JSON.stringify(devices) + ";");
  // console.log('Generated tree: ' + JSON.stringify(tree));

  return {
    tree,
    nodesById,
  };
};

export const buildSubSiteTree = (site, sites) => {
  const sitesId = [];
  const subSites = sites
    .filter(currentSite => {
      if (currentSite.parent_site === site._id) {
        sitesId.push(currentSite._id);
      }

      return (
        currentSite.parent_site === site._id ||
        sitesId.includes(currentSite.parent_site)
      );
    })
    .concat([site], []);

  const { tree } = buildSiteTreeContext(subSites);

  return tree;
};

export const getNodePathString = (nodesById, nodeId) => {
  if (!nodeId) {
    return undefined;
  }

  let path = '';

  do {
    const node = nodesById[nodeId];

    if (!node) {
      break;
    } // Can happen with test data, TODO SHould be removed

    path = ` / ${node.name}${path}`;
    nodeId = node.parent_site; // eslint-disable-line no-param-reassign
  } while (nodeId !== undefined);

  return path;
};

export const getClosestModelId = (nodesById, nodeId, models) => {
  do {
    const node = nodesById[nodeId];

    if (!node) {
      break;
    } // Can happen with test data, TODO SHould be removed

    const model = models.find(m => m.site_id === node._id);
    if (model) {
      return model._id;
    }

    nodeId = node.parent_site; // eslint-disable-line no-param-reassign
  } while (nodeId !== undefined);

  return null;
};

const loopFromNodeToTop = (nodesById, nodeId, eachFunction) => {
  do {
    const node = nodesById[nodeId];

    if (!node) {
      break;
    } // Can happen with test data, TODO Should be removed

    eachFunction(node);

    nodeId = node.parent_site; // eslint-disable-line no-param-reassign
  } while (nodeId !== undefined);

  return null;
};

// Function to retrieve recursivly all the sub devices of a given site
export const retrieveDevices = (node, devices = []) => {
  if (node.children) {
    node.children.forEach(child => {
      if (child.isDevice) {
        devices.push(child);
      } else {
        retrieveDevices(child, devices);
      }
    });
  }

  return devices;
};

export const retrieveSite = (sites, siteId) => {
  if (sites._id === siteId) {
    return sites;
  }

  if (sites.children) {
    for (let i = 0; i < sites.children.length; i++) {
      if (!sites.children[i].isDevice && sites.children[i]._id === siteId) {
        return sites.children[i];
      }

      const site = retrieveSite(sites.children[i], siteId);
      if (site) {
        return site;
      }
    }
  }

  return undefined;
};

export const retrieveSensor = (sensors, sensorName) => {
  let sensor = null;

  sensors.forEach(s => {
    for (let i = 0; i < s.children.length; i++) {
      if (s.children[i].name === sensorName) {
        sensor = s.children[i];
      }
    }
  });

  return sensor;
};

//
// Alerts in tree
//

export const enhanceWithAlerts = (nodesById, alertsByDeviceId) => {
  const alertByNodeId = {};

  // Loop over each deviceId and its alerts
  Object.keys(alertsByDeviceId).forEach(deviceId => {
    const alerts = alertsByDeviceId[deviceId];

    // If alerts, add length to all device's parent
    if (alerts) {
      const alertCount = alerts.length;
      const alertLevel = alerts.reduce(
        (level, alert) => Math.max(level, alert.level),
        0
      );

      loopFromNodeToTop(nodesById, deviceId, node => {
        const alertCtx = alertByNodeId[node._id];
        alertByNodeId[node._id] = {
          alertLevel: alertCtx
            ? Math.max(alertCtx.alertLevel, alertLevel)
            : alertLevel,
          alertCount: (alertCtx ? alertCtx.alertCount : 0) + alertCount,
        };
      });
    }
  });

  return alertByNodeId;
};

//
// Tool functions for building coords box of a given site
//

const defaultBox = {
  ne: {
    lat: 90,
    lng: -180,
  },
  sw: {
    lat: -90,
    lng: 180,
  },
};

const buildCoordBox = (node, bbox) => {
  let newBbox = { ...bbox };
  if (
    node.geo_coords &&
    node.geo_coords.lat !== 0 &&
    node.geo_coords.long !== 0
  ) {
    // eslint-disable-next-line no-param-reassign
    newBbox = {
      ne: {
        lat: Math.min(bbox.ne.lat, node.geo_coords.lat),
        lng: Math.max(bbox.ne.lng, node.geo_coords.long),
      },
      sw: {
        lat: Math.max(bbox.sw.lat, node.geo_coords.lat),
        lng: Math.min(bbox.sw.lng, node.geo_coords.long),
      },
      // east : Math.min(bbox.east, node.geo_coords.long),
      // north : Math.min(bbox.north, node.geo_coords.lat),
      // west : Math.max(bbox.west, node.geo_coords.long),
      // south : Math.max(bbox.south, node.geo_coords.lat)
    };
  }

  if (node.children) {
    node.children.forEach(child => {
      if (child.children) {
        newBbox = buildCoordBox(child, bbox);
      } // eslint-disable-line no-param-reassign

      if (
        child.geo_coords &&
        child.geo_coords.lat !== 0 &&
        child.geo_coords.long !== 0
      ) {
        // eslint-disable-next-line no-param-reassign
        newBbox = {
          ne: {
            lat: Math.min(bbox.ne.lat, child.geo_coords.lat),
            lng: Math.max(bbox.ne.lng, child.geo_coords.long),
          },
          sw: {
            lat: Math.max(bbox.sw.lat, child.geo_coords.lat),
            lng: Math.min(bbox.sw.lng, child.geo_coords.long),
          },
          // east : Math.min(bbox.east, child.geo_coords.long),
          // north : Math.min(bbox.north, child.geo_coords.lat),
          // west : Math.max(bbox.west, child.geo_coords.long),
          // south : Math.max(bbox.south, child.geo_coords.lat)
        };
      }
    });
  }

  // let bbox = [
  //     bounds.getSouthWest().lng(),
  //     bounds.getSouthWest().lat(),
  //     bounds.getNorthEast().lng(),
  //     bounds.getNorthEast().lat(),
  // ];

  return newBbox;
};

export const convertArrayToLatLng = array => {
  const bbox = {
    sw: {
      lng: array[0],
      lat: array[1],
    },
    ne: {
      lng: array[2],
      lat: array[3],
    },
  };

  return bbox;
};

export const getBoundsBox = site => {
  const initialBox = { ...defaultBox };

  let boundsBox = buildCoordBox(site, initialBox);

  // No point found
  if (boundsBox === initialBox) {
    return null;
  }

  // We have only point
  if (
    boundsBox.ne.lat === boundsBox.sw.lat &&
    boundsBox.ne.lng === boundsBox.sw.lng
  ) {
    boundsBox = {
      ne: {
        lat: boundsBox.ne.lat - 0.05,
        lng: boundsBox.ne.lng + 0.05,
      },
      sw: {
        lat: boundsBox.sw.lat + 0.05,
        lng: boundsBox.sw.lng - 0.05,
      },
    };
  }

  return boundsBox;
};

export const getBoundsBoxForElements = elements => {
  let bbox = { ...defaultBox };

  elements.forEach(element => {
    if (
      element.geo_coords &&
      element.geo_coords.lat !== 0 &&
      element.geo_coords.long !== 0
    ) {
      bbox = {
        ne: {
          lat: Math.min(bbox.ne.lat, element.geo_coords.lat),
          lng: Math.max(bbox.ne.lng, element.geo_coords.long),
        },
        sw: {
          lat: Math.max(bbox.sw.lat, element.geo_coords.lat),
          lng: Math.min(bbox.sw.lng, element.geo_coords.long),
        },
      };
    }
  });

  return bbox;
};

export const buildSitesWithPlumes = (sites, plumes) => {
  const buildSites = sites.map(site => {
    plumes.forEach(plume => {
      if (plume.site_id === site._id) {
        site.plumes = plume;
      }
    });
    return site;
  });

  return buildSites;
};
